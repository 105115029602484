<template>
    <div class="container mx-auto bg-gray-100 p-6">
        <h1 class="text-3xl font-bold mb-6">Filtered Data Viewer</h1>
        <div class="flex justify-between items-center mb-4">
            <p class="text-lg font-medium">Total Results: {{ total }}</p>
            <button @click="startSync" :disabled="isSyncing"
                class="bg-green-500 text-white px-6 py-3 rounded hover:bg-green-600 disabled:opacity-50">
                {{ isSyncing ? '同步中...' : '同步数据' }}
            </button>
        </div>

        <form @submit.prevent="fetchData" class="bg-white p-6 shadow-md rounded mb-6">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <div>
                    <label class="block text-lg font-medium text-gray-700 mb-2">Start Time (Beijing Time):</label>
                    <input type="datetime-local" v-model="filters.startTime"
                        class="mt-1 block w-full p-2 border border-gray-300 rounded">
                </div>
                <div>
                    <label class="block text-lg font-medium text-gray-700 mb-2">End Time (Beijing Time):</label>
                    <input type="datetime-local" v-model="filters.endTime"
                        class="mt-1 block w-full p-2 border border-gray-300 rounded">
                </div>
                <div>
                    <label class="block text-lg font-medium text-gray-700 mb-2">Flavor:</label>
                    <select v-model="filters.flavor" class="mt-1 block w-full p-2 border border-gray-300 rounded">
                        <option value="all">All</option>
                        <option value="roast">Roast</option>
                        <option value="boast">Boast</option>
                    </select>
                </div>
                <div>
                    <label class="block text-lg font-medium text-gray-700 mb-2">App Name:</label>
                    <select v-model="filters.appName" class="mt-1 block w-full p-2 border border-gray-300 rounded">
                        <option value="all">All</option>
                        <option value="sider_web">Web</option>
                        <option value="ChitChat_iOS">iOS</option>
                        <option value="sider_android">Android</option>
                    </select>
                </div>
                <div>
                    <label class="block text-lg font-medium text-gray-700 mb-2">IP Address:</label>
                    <input type="text" v-model="filters.extra_ip" placeholder="Enter IP address"
                        class="mt-1 block w-full p-2 border border-gray-300 rounded">
                </div>
                <div>
                    <label class="block text-lg font-medium text-gray-700 mb-2">Content Search:</label>
                    <input type="text" v-model="filters.content" placeholder="Search content"
                        class="mt-1 block w-full p-2 border border-gray-300 rounded">
                </div>
                <div>
                    <label class="block text-lg font-medium text-gray-700 mb-2">User ID:</label>
                    <input type="text" v-model="filters.userId" placeholder="Enter User ID"
                        class="mt-1 block w-full p-2 border border-gray-300 rounded">
                </div>
                <div>
                    <label class="block text-lg font-medium text-gray-700 mb-2">Country:</label>
                    <input type="text" v-model="filters.extra_country" placeholder="Enter Country"
                        class="mt-1 block w-full p-2 border border-gray-300 rounded">
                </div>
            </div>
            <div class="mt-4">
                <button type="submit" class="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600">Apply
                    Filters</button>
            </div>
        </form>

        <table class="table-auto w-full bg-white shadow-md rounded mb-4">
            <thead class="bg-gray-200">
                <tr>
                    <th class="px-4 py-2">ID</th>
                    <th class="px-4 py-2">User ID</th>
                    <th class="px-4 py-2">Flavor</th>
                    <th class="px-4 py-2">Content</th>
                    <th class="px-4 py-2">Image</th>
                    <th class="px-4 py-2">Device</th>
                    <th class="px-4 py-2">Country</th>
                    <th class="px-4 py-2">IP Address</th>
                    <th class="px-4 py-2">Create Time</th>
                    <th class="px-4 py-2">分享次数</th>
                    <th class="px-4 py-2">复制次数</th>
                    <th class="px-4 py-2">下载次数</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in data" :key="item.id">
                    <td class="border px-4 py-2">{{ item.id }}</td>
                    <td class="border px-4 py-2">{{ item.user_id }}</td>
                    <td class="border px-4 py-2">{{ item.flavor }}</td>
                    <td class="border px-4 py-2">{{ item.content }}</td>
                    <td class="border px-4 py-2">
                        <img :src="item.image_url" alt="Image" class="h-24 w-24 object-cover cursor-pointer"
                            @click.prevent="enlargeImage(item.image_url)">
                    </td>
                    <td class="border px-4 py-2">{{ item.app_name }}</td>
                    <td class="border px-4 py-2">{{ item.extra_country }}</td>
                    <td class="border px-4 py-2">{{ item.extra_ip }}</td>
                    <td class="border px-4 py-2">{{ formatTimestamp(item.create_time) }}</td>
                    <td class="border px-4 py-2">{{ item.share_count }}</td>
                    <td class="border px-4 py-2">{{ item.copy_count }}</td>
                    <td class="border px-4 py-2">{{ item.download_count }}</td>
                </tr>
            </tbody>
        </table>

        <div class="flex justify-between items-center mb-4">
            <button @click="previousPage" :disabled="currentPage === 1"
                class="bg-gray-500 text-white px-4 py-2 rounded disabled:opacity-50">Previous</button>
            <span>Page {{ currentPage }} of {{ Math.ceil(total / limit) }}</span>
            <button @click="nextPage" :disabled="currentPage >= Math.ceil(total / limit)"
                class="bg-gray-500 text-white px-4 py-2 rounded disabled:opacity-50">Next</button>
        </div>

        <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center" v-if="showImage"
            @click="closeImage">
            <img :src="enlargedImageSrc" alt="Enlarged Image" class="max-w-3xl max-h-full rounded shadow-lg">
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const isSyncing = ref(false);
const filters = ref({
    startTime: '',
    endTime: '',
    flavor: 'all',
    appName: 'all',
    extra_ip: '',
    content: '',
    userId: '',
    extra_country: ''
});
const data = ref([]);
const total = ref(0);
const currentPage = ref(1);
const limit = 30;
const showImage = ref(false);
const enlargedImageSrc = ref('');

const startSync = async () => {
    if (isSyncing.value) return;
    isSyncing.value = true;
    try {
        const response = await fetch('/api/analyzer/sync', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({})
        });
        const result = await response.json();
        alert(response.ok ? '数据同步完成！' : `同步失败：${result.message}`);
    } catch {
        alert('同步过程中出现错误，请检查控制台日志。');
    } finally {
        isSyncing.value = false;
    }
};

const convertToTimestamp = (dateString) => new Date(dateString).getTime() / 1000;
const formatTimestamp = (timestamp) => new Date(timestamp * 1000).toLocaleString();

const enlargeImage = (imageUrl) => {
    enlargedImageSrc.value = imageUrl;
    showImage.value = true;
};

const closeImage = () => {
    showImage.value = false;
    enlargedImageSrc.value = '';
};

const fetchData = async () => {
    const params = new URLSearchParams();
    if (filters.value.startTime) params.append('start_time', convertToTimestamp(filters.value.startTime));
    if (filters.value.endTime) params.append('end_time', convertToTimestamp(filters.value.endTime));
    if (filters.value.flavor !== 'all') params.append('flavor', filters.value.flavor);
    if (filters.value.appName !== 'all') params.append('app_name', filters.value.appName);
    if (filters.value.extra_ip) params.append('extra_ip', filters.value.extra_ip);
    if (filters.value.content) params.append('content', filters.value.content);
    if (filters.value.userId) params.append('user_id', filters.value.userId);
    if (filters.value.extra_country) params.append('extra_country', filters.value.extra_country);
    params.append('page', currentPage.value);
    params.append('limit', limit);

    try {
        const response = await fetch(`/api/analyzer/list?${params.toString()}`);
        const result = await response.json();
        data.value = result.data || [];
        total.value = result.total || 0;
    } catch {
        data.value = [];
        total.value = 0;
    }
};

const nextPage = () => {
    if (currentPage.value < Math.ceil(total.value / limit)) {
        currentPage.value++;
        fetchData();
    }
};

const previousPage = () => {
    if (currentPage.value > 1) {
        currentPage.value--;
        fetchData();
    }
};

onMounted(fetchData);
</script>

<style scoped>
/* 你可以在这里添加样式 */
</style>