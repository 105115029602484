<template>
    <div class="bg-gray-100 p-6">
      <div class="container mx-auto">
        <!-- 网站名称和导出、导入按钮 -->
        <h1 class="text-2xl font-bold mb-4">数据分析器</h1>
        <div class="flex space-x-2 items-center mb-4">
          <label class="bg-blue-500 text-white p-2 rounded cursor-pointer">
            从文件导入
            <input type="file" @change="importData" class="hidden" />
          </label>
        </div>
  
        <!-- 新增一天的按钮 -->
        <div class="mb-4">
          <input v-model="newDayDate" type="date" class="border border-gray-300 rounded p-2 mr-2" />
          <button @click="addDay" class="bg-blue-500 text-white p-2 rounded mr-2">
            新增一天
          </button>
          <button @click="exportData" class="bg-green-500 text-white p-2 rounded">导出当前数据</button>
        </div>
  
        <!-- 天的容器 -->
        <div v-for="(day, dayIndex) in sortedDays" :key="day.date" class="mb-6">
          <!-- 显示日期和移除按钮 -->
          <div class="flex items-center justify-between mb-2">
            <h2 class="text-xl font-bold">{{ day.date }}</h2>
            <button
              @click="removeDay(dayIndex)"
              class="border border-gray-500 text-gray-500 p-2 rounded bg-transparent hover:bg-gray-200"
            >
              移除该天
            </button>
          </div>
  
          <!-- 横线分割 -->
          <hr class="mb-4" />
  
          <!-- 数据项容器，横向滚动 -->
          <div class="flex space-x-4 overflow-x-auto">
            <div
              v-for="(card, cardIndex) in day.dataItems"
              :key="card.id"
              class="min-w-[250px] bg-white p-4 rounded shadow relative"
            >
              <!-- 数据项的标题和数值 -->
              <h2 class="text-xl">{{ card.title }}</h2>
              <p class="text-3xl font-bold flex items-center justify-between">
                <span>{{ card.value }}</span>
                <span
                  v-if="getDataItemChangePercentage(dayIndex, card) !== null"
                  :class="{
                    'text-green-500': getDataItemChangePercentage(dayIndex, card) > 0,
                    'text-red-500': getDataItemChangePercentage(dayIndex, card) < 0
                  }"
                  class="ml-2 flex items-center text-sm"
                >
                  <svg
                    v-if="getDataItemChangePercentage(dayIndex, card) > 0"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 4a1 1 0 01.894.553l5 10A1 1 0 0115 16H5a1 1 0 01-.894-1.447l5-10A1 1 0 0110 4zm0 2.618L6.618 14h6.764L10 6.618z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <svg
                    v-else-if="getDataItemChangePercentage(dayIndex, card) < 0"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 16a1 1 0 01-.894-.553l-5-10A1 1 0 015 4h10a1 1 0 01.894 1.447l-5 10A1 1 0 0110 16zm0-2.618L13.382 6H6.618L10 13.382z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  {{ Math.abs(getDataItemChangePercentage(dayIndex, card)).toFixed(2) }}%
                </span>
              </p>
  
              <!-- 操作菜单 -->
              <div class="absolute top-2 right-2">
                <div class="relative">
                  <button @click="toggleDataItemMenu(card)" class="p-1 focus:outline-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="currentColor" viewBox="0 0 24 24">
                      <path
                        d="M12 8a2 2 0 100-4 2 2 0 000 4zm0 2a2 2 0 100 4 2 2 0 000-4zm0 6a2 2 0 100 4 2 2 0 000-4z"
                      />
                    </svg>
                  </button>
                  <!-- 菜单项 -->
                  <div
                    v-if="card.showMenu"
                    class="absolute right-0 mt-2 w-24 bg-white border border-gray-200 rounded shadow-lg z-10"
                  >
                    <button
                      @click="editDataItem(dayIndex, card)"
                      class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      编辑
                    </button>
                    <button
                      @click="removeCard(dayIndex, cardIndex)"
                      class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      移除
                    </button>
                  </div>
                </div>
              </div>
  
              <!-- 计算项 -->
              <div v-for="(calc, calcIndex) in card.calculations" :key="calc.id" class="mt-2 border-t pt-2 relative">
                <p class="text-base">{{ calc.title }}</p>
                <p class="text-lg font-bold flex items-center justify-between">
                  <span v-if="getCalculationResult(calc) !== null">
                    {{ getCalculationResult(calc).toFixed(2) }}
                  </span>
                  <span
                    v-if="getCalculationChangePercentage(dayIndex, cardIndex, calc) !== null"
                    :class="{
                      'text-green-500': getCalculationChangePercentage(dayIndex, cardIndex, calc) > 0,
                      'text-red-500': getCalculationChangePercentage(dayIndex, cardIndex, calc) < 0
                    }"
                    class="ml-2 flex items-center text-sm"
                  >
                    <svg
                      v-if="getCalculationChangePercentage(dayIndex, cardIndex, calc) > 0"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 4a1 1 0 01.894.553l5 10A1 1 0 0115 16H5a1 1 0 01-.894-1.447l5-10A1 1 0 0110 4zm0 2.618L6.618 14h6.764L10 6.618z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <svg
                      v-else-if="getCalculationChangePercentage(dayIndex, cardIndex, calc) < 0"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 16a1 1 0 01-.894-.553l-5-10A1 1 0 015 4h10a1 1 0 01.894 1.447l-5 10A1 1 0 0110 16zm0-2.618L13.382 6H6.618L10 13.382z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    {{ Math.abs(getCalculationChangePercentage(dayIndex, cardIndex, calc)).toFixed(2) }}%
                  </span>
                </p>
  
                <!-- 操作菜单 -->
                <div class="absolute top-2 right-2">
                  <div class="relative">
                    <button @click="toggleCalculationMenu(calc)" class="p-1 focus:outline-none">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600" fill="currentColor" viewBox="0 0 24 24">
                        <path
                          d="M12 5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm0 6a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm0 6a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
                        />
                      </svg>
                    </button>
                    <!-- 菜单项 -->
                    <div
                      v-if="calc.showMenu"
                      class="absolute right-0 mt-2 w-24 bg-white border border-gray-200 rounded shadow-lg z-10"
                    >
                      <button
                        @click="editCalculation(dayIndex, cardIndex, calc)"
                        class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        编辑
                      </button>
                      <button
                        @click="removeCalculation(dayIndex, cardIndex, calcIndex)"
                        class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        移除
                      </button>
                    </div>
                  </div>
                </div>
              </div>
  
              <!-- 添加新的计算项按钮 -->
              <button @click="openCalculationModal(dayIndex, cardIndex)" class="mt-4 border border-gray-600 text-gray-600 p-2 rounded w-full">
                添加计算项
              </button>
            </div>
  
            <!-- 右侧空白数据项，点击后添加数据项 -->
            <div
              @click="openDataItemModal(dayIndex)"
              class="min-w-[200px] bg-gray-300 p-4 rounded shadow cursor-pointer flex items-center justify-center"
            >
              <h2 class="text-xl font-bold text-center text-gray-600">+ 添加数据项</h2>
            </div>
          </div>
        </div>
  
        <!-- 弹出层，用于添加或编辑数据项 -->
        <div v-if="showDataItemModal" class="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
          <div class="bg-white p-6 rounded shadow-lg w-80">
            <h2 class="text-xl font-bold mb-4">{{ isEditingDataItem ? '编辑数据项' : '添加数据项' }}</h2>
            <input v-model="newCardTitle" class="border border-gray-300 rounded p-2 mb-4 w-full" placeholder="输入标题" />
            <input v-model="newCardValue" type="number" class="border border-gray-300 rounded p-2 mb-4 w-full" placeholder="输入数值" />
            <div class="mt-4 flex justify-end space-x-2">
              <button @click="closeDataItemModal" class="bg-gray-500 text-white p-2 rounded">取消</button>
              <button @click="saveDataItem" class="bg-blue-500 text-white p-2 rounded">确认</button>
            </div>
          </div>
        </div>
  
        <!-- 弹出层，用于添加或编辑计算项 -->
        <div v-if="showCalculationModal" class="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
          <div class="bg-white p-6 rounded shadow-lg w-80">
            <h2 class="text-xl font-bold mb-4">{{ isEditingCalculation ? '编辑计算项' : '添加计算项' }}</h2>
            <input v-model="newCalcTitle" class="border border-gray-300 rounded p-2 mb-4 w-full" placeholder="计算项标题" />
  
            <!-- 第一个数据来源 -->
            <label class="block mb-2">选择第一个数据来源</label>
            <select v-model="newCalcFirstSource" class="border border-gray-300 rounded p-2 w-full">
              <option disabled value="">请选择数据项</option>
              <optgroup :label="currentDay.date">
                <option v-for="(card, cardIndex) in currentDay.dataItems" :key="card.id" :value="card.id">
                  {{ card.title }}
                </option>
              </optgroup>
            </select>
  
            <!-- 选择计算方式 -->
            <label class="block mb-2">选择计算方式</label>
            <select v-model="newCalcOperation" class="border border-gray-300 rounded p-2 w-full">
              <option disabled value="">请选择计算方式</option>
              <option value="add">加法 (+)</option>
              <option value="subtract">减法 (-)</option>
              <option value="multiply">乘法 (×)</option>
              <option value="divide">除法 (÷)</option>
            </select>
  
            <!-- 第二个数据来源 -->
            <label class="block mb-2">选择第二个数据来源</label>
            <select v-model="newCalcSecondSource" class="border border-gray-300 rounded p-2 w-full">
              <option disabled value="">请选择数据项</option>
              <optgroup :label="currentDay.date">
                <option v-for="(card, cardIndex) in currentDay.dataItems" :key="card.id" :value="card.id">
                  {{ card.title }}
                </option>
              </optgroup>
            </select>
  
            <div class="mt-4 flex justify-end space-x-2">
              <button @click="closeCalculationModal" class="bg-gray-500 text-white p-2 rounded">取消</button>
              <button @click="saveCalculation" class="bg-blue-500 text-white p-2 rounded">确认</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        newDayDate: "",
        days: [],
        newCardTitle: "",
        newCardValue: "",
        showDataItemModal: false,
        showCalculationModal: false,
        selectedDayIndexForDataItemModal: null,
        selectedCardIndexForDataItemModal: null,
        isEditingDataItem: false,
        editingDataItem: null,
        selectedDayIndexForCalculationModal: null,
        selectedCardIndexForCalculationModal: null,
        isEditingCalculation: false,
        editingCalculation: null,
        newCalcTitle: "",
        newCalcFirstSource: "",
        newCalcSecondSource: "",
        newCalcOperation: "",
        dataFiles: [], // 存储可用的数据文件列表
        selectedDataFile: "", // 当前选择的数据文件
      };
    },
    computed: {
      sortedDays() {
        return this.days.sort((a, b) => new Date(a.date) - new Date(b.date));
      },
      currentDay() {
        return this.days[this.selectedDayIndexForCalculationModal];
      },
    },
    methods: {
      // 导出数据
      exportData() {
        if (this.days.length === 0) {
          alert("没有可导出的数据！");
          return;
        }
  
        const dates = this.days
          .map((day) => day.date)
          .sort((a, b) => new Date(a) - new Date(b));
        const startDate = dates[0];
        const endDate = dates[dates.length - 1];
  
        const dataStr = JSON.stringify(this.days);
        const dataUri = "data:application/json;charset=utf-8," + encodeURIComponent(dataStr);
  
        const exportFileDefaultName = `${startDate}-${endDate}.js`;
  
        let linkElement = document.createElement("a");
        linkElement.setAttribute("href", dataUri);
        linkElement.setAttribute("download", exportFileDefaultName);
        linkElement.click();
      },
      // 导入数据
      importData(event) {
        const file = event.target.files[0];
        if (!file) return;
  
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const importedData = JSON.parse(e.target.result);
            if (Array.isArray(importedData)) {
              this.days = importedData;
              event.target.value = "";
            } else {
              alert("导入的数据格式不正确！");
            }
          } catch (error) {
            alert("无法解析导入的数据！");
          }
        };
        reader.readAsText(file);
      },
      // 添加新的一天
      addDay() {
        if (this.newDayDate) {
          if (this.days.some((day) => day.date === this.newDayDate)) {
            alert("该日期已存在！");
            return;
          }
  
          let newDay = {
            date: this.newDayDate,
            dataItems: [],
          };
  
          if (this.days.length > 0) {
            const lastDayDataItems = this.days[this.days.length - 1].dataItems;
  
            const idMap = {};
  
            newDay.dataItems = lastDayDataItems.map((item) => {
              const newItem = JSON.parse(JSON.stringify(item));
              const oldId = newItem.id;
              newItem.id = Date.now() + Math.random();
              idMap[oldId] = newItem.id;
  
              newItem.calculations.forEach((calc) => {
                if (idMap[calc.firstDataItemId]) {
                  calc.firstDataItemId = idMap[calc.firstDataItemId];
                }
                if (idMap[calc.secondDataItemId]) {
                  calc.secondDataItemId = idMap[calc.secondDataItemId];
                }
                calc.id = Date.now() + Math.random();
                calc.showMenu = false;
              });
  
              newItem.showMenu = false;
              return newItem;
            });
          }
  
          this.days.push(newDay);
          this.newDayDate = "";
        }
      },
      // 移除一天
      removeDay(dayIndex) {
        if (confirm("确定要移除该天及其所有数据项吗？")) {
          this.days.splice(dayIndex, 1);
        }
      },
      // 打开添加数据项的弹出层
      openDataItemModal(dayIndex) {
        this.selectedDayIndexForDataItemModal = dayIndex;
        this.showDataItemModal = true;
        this.isEditingDataItem = false;
        this.editingDataItem = null;
        this.newCardTitle = "";
        this.newCardValue = "";
      },
      // 打开编辑数据项的弹出层
      editDataItem(dayIndex, dataItem) {
        this.selectedDayIndexForDataItemModal = dayIndex;
        this.showDataItemModal = true;
        this.isEditingDataItem = true;
        this.editingDataItem = dataItem;
        this.newCardTitle = dataItem.title;
        this.newCardValue = dataItem.value;
        dataItem.showMenu = false;
      },
      closeDataItemModal() {
        this.showDataItemModal = false;
        this.newCardTitle = "";
        this.newCardValue = "";
        this.editingDataItem = null;
      },
      saveDataItem() {
        if (this.newCardTitle && this.newCardValue !== "") {
          if (this.isEditingDataItem && this.editingDataItem) {
            this.editingDataItem.title = this.newCardTitle;
            this.editingDataItem.value = parseFloat(this.newCardValue);
          } else {
            this.days[this.selectedDayIndexForDataItemModal].dataItems.push({
              id: Date.now() + Math.random(),
              title: this.newCardTitle,
              value: parseFloat(this.newCardValue),
              calculations: [],
              showMenu: false,
            });
          }
          this.closeDataItemModal();
        }
      },
      toggleDataItemMenu(card) {
        card.showMenu = !card.showMenu;
      },
      removeCard(dayIndex, cardIndex) {
        if (confirm("确定要删除该数据项吗？")) {
          this.days[dayIndex].dataItems.splice(cardIndex, 1);
        }
      },
      openCalculationModal(dayIndex, cardIndex) {
        this.selectedDayIndexForCalculationModal = dayIndex;
        this.selectedCardIndexForCalculationModal = cardIndex;
        this.showCalculationModal = true;
        this.isEditingCalculation = false;
        this.editingCalculation = null;
        this.newCalcTitle = "";
        this.newCalcFirstSource = "";
        this.newCalcSecondSource = "";
        this.newCalcOperation = "";
      },
      editCalculation(dayIndex, cardIndex, calc) {
        this.selectedDayIndexForCalculationModal = dayIndex;
        this.selectedCardIndexForCalculationModal = cardIndex;
        this.showCalculationModal = true;
        this.isEditingCalculation = true;
        this.editingCalculation = calc;
        this.newCalcTitle = calc.title;
        this.newCalcFirstSource = calc.firstDataItemId;
        this.newCalcSecondSource = calc.secondDataItemId;
        this.newCalcOperation = calc.operation;
        calc.showMenu = false;
      },
      closeCalculationModal() {
        this.showCalculationModal = false;
        this.newCalcTitle = "";
        this.newCalcFirstSource = "";
        this.newCalcSecondSource = "";
        this.newCalcOperation = "";
        this.editingCalculation = null;
      },
      saveCalculation() {
        if (this.newCalcTitle && this.newCalcFirstSource !== "" && this.newCalcSecondSource !== "" && this.newCalcOperation !== "") {
          if (this.isEditingCalculation && this.editingCalculation) {
            this.editingCalculation.title = this.newCalcTitle;
            this.editingCalculation.firstDataItemId = this.newCalcFirstSource;
            this.editingCalculation.secondDataItemId = this.newCalcSecondSource;
            this.editingCalculation.operation = this.newCalcOperation;
          } else {
            const calc = {
              id: Date.now() + Math.random(),
              title: this.newCalcTitle,
              firstDataItemId: this.newCalcFirstSource,
              secondDataItemId: this.newCalcSecondSource,
              operation: this.newCalcOperation,
              showMenu: false,
            };
  
            this.days[this.selectedDayIndexForCalculationModal].dataItems[
              this.selectedCardIndexForCalculationModal
            ].calculations.push(calc);
          }
          this.closeCalculationModal();
        }
      },
      toggleCalculationMenu(calc) {
        calc.showMenu = !calc.showMenu;
      },
      removeCalculation(dayIndex, cardIndex, calcIndex) {
        if (confirm("确定要删除该计算项吗？")) {
          this.days[dayIndex].dataItems[cardIndex].calculations.splice(calcIndex, 1);
        }
      },
      findDataItemById(id) {
        for (let day of this.days) {
          for (let dataItem of day.dataItems) {
            if (dataItem.id == id) {
              return dataItem;
            }
          }
        }
        return null;
      },
      getCalculationResult(calc) {
        const firstDataItem = this.findDataItemById(calc.firstDataItemId);
        const secondDataItem = this.findDataItemById(calc.secondDataItemId);
  
        if (!firstDataItem || !secondDataItem) {
          return null;
        }
  
        const firstValue = firstDataItem.value;
        const secondValue = secondDataItem.value;
  
        if (firstValue !== undefined && secondValue !== undefined) {
          switch (calc.operation) {
            case "add":
              return firstValue + secondValue;
            case "subtract":
              return firstValue - secondValue;
            case "multiply":
              return firstValue * secondValue;
            case "divide":
              return secondValue !== 0 ? firstValue / secondValue : null;
            default:
              return null;
          }
        }
        return null;
      },
      getDataItemChangePercentage(dayIndex, dataItem) {
        if (dayIndex === 0) return null;
  
        const previousDay = this.sortedDays[dayIndex - 1];
        const previousDataItem = previousDay.dataItems.find(
          (item) => item.title === dataItem.title
        );
  
        if (!previousDataItem) return null;
  
        const previousValue = previousDataItem.value;
        const currentValue = dataItem.value;
  
        if (previousValue === 0) return null;
  
        const changePercentage =
          ((currentValue - previousValue) / Math.abs(previousValue)) * 100;
        return changePercentage;
      },
      getCalculationChangePercentage(dayIndex, cardIndex, calc) {
        if (dayIndex === 0) return null;
  
        const previousDay = this.sortedDays[dayIndex - 1];
        const previousCard = previousDay.dataItems[cardIndex];
  
        if (!previousCard) return null;
  
        const previousCalc = previousCard.calculations.find(
          (c) => c.title === calc.title
        );
  
        if (!previousCalc) return null;
  
        const previousResult = this.getCalculationResult(previousCalc);
        const currentResult = this.getCalculationResult(calc);
  
        if (previousResult === null || previousResult === 0) return null;
  
        const changePercentage =
          ((currentResult - previousResult) / Math.abs(previousResult)) * 100;
        return changePercentage;
      },
    },
  };
  </script>
  
  <style scoped>
  /* 你可以在此处添加 Tailwind CSS 或其他定制样式 */
  </style>
  