<template>
    <div class="flex h-screen">
        <!-- 左边APP界面 -->
        <div class="flex-1 flex border-r border-gray-300 overflow-y-auto">
            <!-- 左侧树形结构 -->
            <div class="w-[300px] border-r border-gray-300 p-4 overflow-y-auto">
                <h2 class="text-xl font-bold mb-4">Files</h2>
                <tree-view :files="files" @file-click="handleFileClick" />
            </div>

            <!-- 右侧文件内容/编辑区 -->
            <div class="flex-1 flex flex-col p-6 bg-gray-50">
                <div class="mb-4 flex justify-between items-center">
                    <div class="breadcrumbs">
                    <span 
                        v-for="(crumb, index) in breadcrumbs" 
                        :key="index" 
                        class="cursor-pointer text-blue-500 hover:underline"
                        @click="navigateToCrumb(index)"
                    >
                        {{ crumb }}
                        <span v-if="index < breadcrumbs.length - 1"> / </span>
                    </span>
                </div>
                    <button @click="createNewFile" class="bg-blue-500 text-white px-4 py-2 rounded">
                        New File
                    </button>
                </div>

                <!-- 面包屑导航 -->
               

                <!-- 统一的文件编辑区 -->
                <div v-if="currentFile" class="flex-1 flex flex-col">
                    <div class="flex justify-between items-center mb-2">
                        <input 
                            v-model="currentFile.name" 
                            :readonly="!currentFile.isNew"
                            class="text-xl font-semibold p-2 border rounded"
                            :class="{ 'bg-gray-100': !currentFile.isNew }"
                        />
                        <button @click="saveFile" class="bg-green-500 text-white px-4 py-2 rounded">
                            {{ currentFile.isNew ? 'Create' : 'Save' }}
                        </button>
                    </div>
                    <textarea 
                        v-model="currentFile.content" 
                        class="flex-1 w-full p-2 border rounded"
                    ></textarea>
                </div>
                <div v-else class="flex-1 flex items-center justify-center text-gray-500">
                    Select a file to edit or create a new file
                </div>
            </div>
        </div>

        <!-- 右边模型选择和 Chat 组件 -->
        <div class="w-[400px] flex flex-col">
            <!-- 模型选择下拉菜单 -->
            <div class="flex justify-center mt-2">
                <select v-model="selectedModel" class="p-2 text-sm border border-gray-300 rounded">
                    <option v-for="model in models" :key="model" :value="model">
                        {{ model }}
                    </option>
                </select>
            </div>
            <!-- Chat 组件 -->
            <chat :model="selectedModel" :prompt="prompt" :tools="tools" @run-tool="runTool" ref="Ai" />
        </div>
    </div>
</template>


<script setup>
import { ref, onMounted } from 'vue';
import chat from '../components/AI/chat/_old/openaiChatStream.vue';
import TreeView from '../components/TreeView.vue';
import { models, post } from '../utils';
import { tools } from '../utils/tools/git';

const selectedModel = ref('gpt-4o-mini');
const Ai = ref(null);
const prompt = `你是一个有用的助手`;
const files = ref([]);
const currentPath = ref('/');
const breadcrumbs = ref(['Root']);
const currentFile = ref(null);

// 辅助函数保持不变
const cleanPath = (path) => {
    return path.replace(/^\/+/, '').replace(/\/+$/, '');
};

const combinePaths = (...paths) => {
    return '/' + paths.map(cleanPath).filter(Boolean).join('/');
};

// UI 操作函数
const openDirectory = async (path) => {
    try {
        const response = await post('/api/git/getFiles', { path: cleanPath(path) });
        files.value = response;
        currentPath.value = path;
        updateBreadcrumbs(path);
        return response;
    } catch (error) {
        console.error('Error opening directory:', error);
        throw error;
    }
};

const openFile = async (filePath) => {
    try {
        const response = await post('/api/git/openFile', { filePath: cleanPath(filePath) });
        currentFile.value = {
            name: filePath.split('/').pop(),
            path: filePath,
            content: response.content,
            isNew: false
        };
        return response.content;
    } catch (error) {
        console.error('Error opening file:', error);
        throw error;
    }
};

const createFile = async (filePath, content, message = 'Add new file') => {
    try {
        const cleanedPath = cleanPath(filePath);
        const response = await post('/api/git/createNewFile', { 
            filePath: cleanedPath, 
            content,
            message
        });
        await openDirectory(currentPath.value); // 刷新当前目录
        return response;
    } catch (error) {
        console.error('Error creating file:', error);
        throw error;
    }
};

const editFile = async (filePath, content, message = 'Edit file') => {
    try {
        const response = await post('/api/git/editFile', { 
            filePath: cleanPath(filePath), 
            content,
            message
        });
        if (currentFile.value && currentFile.value.path === filePath) {
            currentFile.value.content = content;
        }
        await openDirectory(currentPath.value); // 刷新当前目录
        return response;
    } catch (error) {
        console.error('Error editing file:', error);
        throw error;
    }
};

// UI 事件处理函数
const handleFileClick = async (file) => {
    if (file.type === 'directory') {
        await openDirectory(file.path);
    } else {
        await openFile(file.path);
    }
};

const createNewFile = () => {
    currentFile.value = {
        name: '',
        path: currentPath.value,
        content: '',
        isNew: true
    };
};

const saveFile = async () => {
    if (currentFile.value) {
        try {
            let filePath;
            if (currentFile.value.isNew) {
                filePath = combinePaths(currentPath.value, currentFile.value.name);
            } else {
                filePath = currentFile.value.path;
            }

            if (currentFile.value.isNew) {
                await createFile(filePath, currentFile.value.content, `Add ${currentFile.value.name}`);
                console.log('New file created successfully');
            } else {
                await editFile(filePath, currentFile.value.content, `Update ${currentFile.value.name}`);
                console.log('File saved successfully');
            }

            await openDirectory(currentPath.value); // 刷新当前目录
            if (currentFile.value.isNew) {
                await openFile(filePath); // 打开新创建的文件
            }
        } catch (error) {
            console.error('Failed to save file:', error);
            // 可以添加一个保存失败的提示
        }
    }
};

const updateBreadcrumbs = (path) => {
    const parts = path.split('/').filter(Boolean);
    breadcrumbs.value = ['Root', ...parts];
};

const navigateToCrumb = async (index) => {
    const path = index === 0 ? '/' : '/' + breadcrumbs.value.slice(1, index + 1).join('/');
    await openDirectory(path);
};

// runTool 函数
const runTool = async (toolCalls) => {
    let toolMessagesList = [];

    for (const item of toolCalls) {
        let { id, function: func } = item;
        let { name, arguments: args } = func;
        let funContent = '';

        try {
            const parsedArgs = JSON.parse(args);
            switch (name) {
                case 'getFiles':
                    funContent = JSON.stringify(await openDirectory(parsedArgs.path || ''));
                    break;
                case 'openFile':
                    funContent = await openFile(parsedArgs.filePath);
                    break;
                case 'createNewFile':
                    funContent = JSON.stringify(await createFile(
                        parsedArgs.filePath, 
                        parsedArgs.content, 
                        parsedArgs.message || 'Add new file'
                    ));
                    break;
                case 'editFile':
                    funContent = JSON.stringify(await editFile(
                        parsedArgs.filePath, 
                        parsedArgs.content, 
                        parsedArgs.message || 'Edit file'
                    ));
                    break;
                default:
                    throw new Error(`未知的函数名: ${name}`);
            }
        } catch (error) {
            funContent = `执行 ${name} 时出错: ${error.message}`;
        }

        toolMessagesList.push({
            tool_call_id: id,
            role: "tool",
            name: name,
            content: funContent,
            expanded: false
        });
    }

    if (Ai.value && typeof Ai.value.addToolMessages === 'function') {
        Ai.value.addToolMessages(toolMessagesList);
    } else {
        console.error('无法访问子组件的方法 addToolMessages');
    }
};

onMounted(async () => {
    await openDirectory('/');
});
</script>