<template>
    <div>
      <h2 class="text-2xl font-bold mb-4">Task Management</h2>
  
      <!-- 立即执行所有任务按钮 -->
      <div class="mb-6 text-right">
        <button @click="executeAllTasks" class="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mr-4">
          Execute All Tasks
        </button>
        <router-link to="/inews/task/create" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
          Add New Task
        </router-link>
      </div>
  
      <div v-if="loading" class="text-center">
        <p>Loading tasks...</p>
      </div>
  
      <div v-if="!loading && tasks.length === 0" class="text-center">
        <p>No tasks available.</p>
      </div>
  
      <div v-if="!loading && tasks.length > 0">
        <ul>
          <li v-for="task in tasks" :key="task.id" class="p-4 bg-white mb-2 shadow rounded-lg flex justify-between">
            <div>
              <h3 class="text-xl font-semibold">{{ task.task_name }}</h3>
              <p>{{ task.task_description || 'No description provided' }}</p>
            </div>
            <div class="flex items-center space-x-4">
              <router-link :to="`/inews/task/edit/${task.id}`" class="text-blue-500 underline">Edit</router-link>
              <button @click="deleteTask(task.id)" class="text-red-500 underline">Delete</button>
              <button @click="executeTask(task.id)" class="text-green-500 underline">Execute Now</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import { get, post } from '../../utils';
  
  const tasks = ref([]);
  const loading = ref(true);
  
  // Fetch tasks from the backend
  const fetchTasks = async () => {
    try {
      const response = await get('/api/inews/task/list');
      tasks.value = response;
    } catch (error) {
      console.error('Failed to fetch tasks:', error);
    } finally {
      loading.value = false;
    }
  };
  
  // Execute all tasks
  const executeAllTasks = async () => {
    try {
      // 循环执行每个任务
      for (const task of tasks.value) {
        await executeTask(task.id);  // 调用 executeTask 函数执行单个任务
      }
      alert('All tasks executed successfully');
    } catch (error) {
      console.error('Failed to execute all tasks:', error);
    }
  };
  
  // Execute a single task
  const executeTask = async (taskId) => {
    try {
      const response = await post('/api/inews/task/execute', { taskId });
      alert(response.message);
    } catch (error) {
      console.error('Failed to execute task:', error);
    }
  };
  
  // Delete a single task
  const deleteTask = async (taskId) => {
    try {
      await post(`/api/inews/task/delete`, { id: taskId });
      tasks.value = tasks.value.filter(task => task.id !== taskId);
    } catch (error) {
      console.error('Failed to delete task:', error);
    }
  };
  
  // Fetch tasks on mount
  onMounted(fetchTasks);
  </script>
  
  <style scoped>
  /* TailwindCSS 样式 */
  </style>
  