<template>
    <div class="container mx-auto">
      <h1 class="text-3xl font-bold mb-6">数据可视化 - 每小时/每天数量</h1>
  
      <!-- 筛选条件表单 -->
      <form @submit.prevent="fetchData" class="bg-white p-6 shadow-md rounded mb-6">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <!-- 开始时间筛选 -->
          <div>
            <label class="block text-lg font-medium text-gray-700 mb-2">开始时间（北京时间）：</label>
            <input type="datetime-local" v-model="filters.startTime" class="mt-1 block w-full p-2 border border-gray-300 rounded">
          </div>
  
          <!-- 结束时间筛选 -->
          <div>
            <label class="block text-lg font-medium text-gray-700 mb-2">结束时间（北京时间）：</label>
            <input type="datetime-local" v-model="filters.endTime" class="mt-1 block w-full p-2 border border-gray-300 rounded">
          </div>
  
          <!-- Flavor 下拉选择框 -->
          <div>
            <label class="block text-lg font-medium text-gray-700 mb-2">Flavor：</label>
            <select v-model="filters.flavor" class="mt-1 block w-full p-2 border border-gray-300 rounded">
              <option value="all">全部</option>
              <option value="roast">Roast</option>
              <option value="boast">Boast</option>
            </select>
          </div>
  
          <!-- App Name 下拉选择框 -->
          <div>
            <label class="block text-lg font-medium text-gray-700 mb-2">App 名称：</label>
            <select v-model="filters.appName" class="mt-1 block w-full p-2 border border-gray-300 rounded">
              <option value="all">全部</option>
              <option value="sider_web">Web</option>
              <option value="ChitChat_iOS">iOS</option>
              <option value="sider_android">Android</option>
            </select>
          </div>
        </div>
  
        <div class="mt-4">
          <button type="submit" class="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600">应用筛选</button>
        </div>
      </form>
  
      <!-- 第一个柱状图：显示总和 -->
      <div v-if="chartData && total > 0" class="bg-white p-6 shadow-md rounded mb-6">
        <h2 class="text-xl font-semibold mb-4">总和显示柱状图</h2>
        <canvas ref="barChartTotal"></canvas>
      </div>
  
      <!-- 第二个柱状图：按分类显示 -->
      <div v-if="chartData && total > 0" class="bg-white p-6 shadow-md rounded mb-6">
        <h2 class="text-xl font-semibold mb-4">按分类显示柱状图</h2>
        <canvas ref="barChartCategory"></canvas>
      </div>
  
      <!-- 第三个图表：Flavor 占比折线图 -->
      <div v-if="chartData && total > 0" class="bg-white p-6 shadow-md rounded mb-6">
        <h2 class="text-xl font-semibold mb-4">Flavor 显示占比折线图</h2>
        <canvas ref="lineChartFlavor"></canvas>
      </div>
  
      <!-- 第四个图表：extra_country 的前五占比折线图 -->
      <div v-if="chartData && total > 0" class="bg-white p-6 shadow-md rounded mb-6">
        <h2 class="text-xl font-semibold mb-4">Country 显示占比折线图</h2>
        <canvas ref="lineChartCountry"></canvas>
      </div>
  
      <!-- 第五个图表：share_count、copy_count、download_count 的折线图 -->
      <div v-if="chartData && total > 0" class="bg-white p-6 shadow-md rounded mb-6">
        <h2 class="text-xl font-semibold mb-4">分享/复制/下载数量折线图</h2>
        <canvas ref="lineChartActivity"></canvas>
      </div>
  
      <!-- 无数据提示 -->
      <div v-if="total === 0" class="text-center text-gray-500">
        <p>暂无数据，请调整筛选条件。</p>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, nextTick, onUnmounted } from 'vue';
  import { Chart, registerables } from 'chart.js';
  
  // 注册 Chart.js 所有必要的组件
  Chart.register(...registerables);
  
  // 定义筛选条件、图表数据等状态
  const filters = ref({
    startTime: '',
    endTime: '',
    flavor: 'all',
    appName: 'all',
  });
  const total = ref(0);
  const chartTotal = ref(null);  // Chart.js 实例用于显示总和的柱状图
  const chartCategory = ref(null);  // Chart.js 实例用于显示按分类的柱状图
  const chartFlavor = ref(null);  // Chart.js 实例用于显示 Flavor 占比折线图
  const chartCountry = ref(null);  // Chart.js 实例用于显示 extra_country 占比折线图
  const chartActivity = ref(null);  // Chart.js 实例用于显示 share_count、copy_count、download_count 的折线图
  const chartData = ref(null);  // 图表数据
  const barChartTotal = ref(null);  // 总和图表的 canvas DOM 的 ref
  const barChartCategory = ref(null);  // 分类图表的 canvas DOM 的 ref
  const lineChartFlavor = ref(null);  // Flavor 占比折线图的 canvas DOM 的 ref
  const lineChartCountry = ref(null);  // Country 占比折线图的 canvas DOM 的 ref
  const lineChartActivity = ref(null);  // Activity 折线图的 canvas DOM 的 ref
  
  // 颜色列表，用于不同的 App 名称和 Flavor
  const appColors = {
    sider_web: 'rgba(59, 130, 246, 0.5)',
    ChitChat_iOS: 'rgba(34, 197, 94, 0.5)',
    sider_android: 'rgba(234, 179, 8, 0.5)',
  };
  
  // 国家颜色列表
  const countryColors = [
    'rgba(255, 99, 132, 0.5)',
    'rgba(54, 162, 235, 0.5)',
    'rgba(255, 206, 86, 0.5)',
    'rgba(75, 192, 192, 0.5)',
    'rgba(153, 102, 255, 0.5)',
  ];
  
  // 获取数据的方法
  const fetchData = async () => {
    if (!filters.value.startTime || !filters.value.endTime) {
      alert('请先选择时间范围');
      return;
    }
  
    const params = new URLSearchParams();
    const startTimeStamp = Math.floor(new Date(filters.value.startTime).getTime() / 1000);
    const endTimeStamp = Math.floor(new Date(filters.value.endTime).getTime() / 1000);
  
    params.append('start_time', startTimeStamp);
    params.append('end_time', endTimeStamp);
  
    if (filters.value.flavor !== 'all') {
      params.append('flavor', filters.value.flavor);
    }
    if (filters.value.appName !== 'all') {
      params.append('app_name', filters.value.appName);
    }
  
    try {
      const response = await fetch(`/api/analyzer/list?${params.toString()}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      if (result.data) {
        processChartData(result.data, startTimeStamp, endTimeStamp);
        total.value = result.total;
      } else {
        chartData.value = null;
        total.value = 0;
      }
    } catch (error) {
      console.error('Error:', error);
      chartData.value = null;
      total.value = 0;
    }
  };
  
  // 处理数据并准备图表
  const processChartData = (data, startTimeStamp, endTimeStamp) => {
    const timeDiff = endTimeStamp - startTimeStamp;
    const oneDay = 86400; // 一天的秒数
    let groupByHour = timeDiff < oneDay; // 小于一天按小时展示
  
    const counts = {};
    const countsTotal = {}; // 总和数据
    const appNames = new Set();  // 记录所有 App 名称
    const flavorCounts = { roast: {}, boast: {} };  // 用于存储 Roast 和 Boast 的数据
    const countryCounts = {};  // 用于存储 country 的数据
    const activityCounts = { share_count: {}, copy_count: {}, download_count: {} };  // 用于存储 share_count、copy_count、download_count
  
    data.forEach(item => {
      const timestamp = item.create_time * 1000;
      const date = new Date(timestamp);
      const appName = item.app_name;
      const flavor = item.flavor.toLowerCase();  // Flavor 转为小写
      const country = item.extra_country;
  
      appNames.add(appName);
  
      let key;
      if (groupByHour) {
        key = `${date.getHours()}:00`;
      } else {
        key = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      }
  
      if (!counts[key]) counts[key] = {};
      if (!countsTotal[key]) countsTotal[key] = 0;  // 记录总和
      if (!flavorCounts.roast[key]) flavorCounts.roast[key] = 0;
      if (!flavorCounts.boast[key]) flavorCounts.boast[key] = 0;
      if (!countryCounts[country]) countryCounts[country] = {};
      if (!activityCounts.share_count[key]) activityCounts.share_count[key] = 0;
      if (!activityCounts.copy_count[key]) activityCounts.copy_count[key] = 0;
      if (!activityCounts.download_count[key]) activityCounts.download_count[key] = 0;
  
      if (!counts[key][appName]) counts[key][appName] = 0;
      counts[key][appName] += 1;
      countsTotal[key] += 1;  // 累加总和
  
      // 累加 Flavor 数据
      if (flavor === 'roast') {
        flavorCounts.roast[key] += 1;
      } else if (flavor === 'boast') {
        flavorCounts.boast[key] += 1;
      }
  
      // 累加 extra_country 数据
      if (!countryCounts[country][key]) countryCounts[country][key] = 0;
      countryCounts[country][key] += 1;
  
      // 累加 share_count, copy_count, download_count 数据
      activityCounts.share_count[key] += item.share_count;
      activityCounts.copy_count[key] += item.copy_count;
      activityCounts.download_count[key] += item.download_count;
    });
  
    const labels = Object.keys(counts);
  
    // 准备总和数据的 `datasets`
    const totalData = Object.values(countsTotal);
    const datasetTotal = [{
      label: '总和数据',
      data: totalData,
      backgroundColor: 'rgba(59, 130, 246, 0.5)',
      borderColor: 'rgba(59, 130, 246, 1)',
      borderWidth: 1,
    }];
  
    // 准备按分类显示的 `datasets`
    const datasetsCategory = Array.from(appNames).map(appName => {
      const data = labels.map(label => counts[label][appName] || 0);  // 处理没有数据的情况
      return {
        label: appName,
        data: data,
        backgroundColor: appColors[appName] || 'rgba(75, 192, 192, 0.5)',  // 如果没有颜色则设置默认
        borderColor: appColors[appName] || 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      };
    });
  
    // 处理 extra_country 占比，只取前五个
    const topCountries = Object.entries(countryCounts)
      .sort((a, b) => Object.values(b[1]).reduce((sum, value) => sum + value, 0) - Object.values(a[1]).reduce((sum, value) => sum + value, 0))
      .slice(0, 5);
  
    const datasetsCountry = topCountries.map(([country, data], index) => {
      const countryData = labels.map(label => data[label] || 0);
      return {
        label: country,
        data: countryData,
        backgroundColor: countryColors[index % countryColors.length],
        borderColor: countryColors[index % countryColors.length],
        fill: false,
        tension: 0.1,
      };
    });
  
    // 准备 Flavor 占比数据的 `datasets`
    const roastData = labels.map(label => flavorCounts.roast[label] || 0);
    const boastData = labels.map(label => flavorCounts.boast[label] || 0);
    const datasetFlavor = [
      {
        label: 'Roast 占比',
        data: roastData,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        fill: false,
        tension: 0.1,
      },
      {
        label: 'Boast 占比',
        data: boastData,
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        fill: false,
        tension: 0.1,
      },
    ];
  
    // 准备 Activity 数据的 `datasets`
    const datasetActivity = [
      {
        label: 'Share Count',
        data: labels.map(label => activityCounts.share_count[label] || 0),
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        fill: false,
        tension: 0.1,
      },
      {
        label: 'Copy Count',
        data: labels.map(label => activityCounts.copy_count[label] || 0),
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        borderColor: 'rgba(255, 159, 64, 1)',
        fill: false,
        tension: 0.1,
      },
      {
        label: 'Download Count',
        data: labels.map(label => activityCounts.download_count[label] || 0),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        fill: false,
        tension: 0.1,
      },
    ];
  
    chartData.value = {
      labels,
      totalDataset: datasetTotal,
      categoryDatasets: datasetsCategory,
      flavorDatasets: datasetFlavor,
      countryDatasets: datasetsCountry,
      activityDatasets: datasetActivity,
    };
  
    nextTick(() => {
      renderCharts();
    });
  };
  
  // 渲染五个 Chart.js 图表
  const renderCharts = () => {
    if (chartData.value && barChartTotal.value && barChartCategory.value && lineChartFlavor.value && lineChartCountry.value && lineChartActivity.value) {
      const ctxTotal = barChartTotal.value.getContext('2d');
      const ctxCategory = barChartCategory.value.getContext('2d');
      const ctxFlavor = lineChartFlavor.value.getContext('2d');
      const ctxCountry = lineChartCountry.value.getContext('2d');
      const ctxActivity = lineChartActivity.value.getContext('2d');
  
      // 销毁旧的图表实例，以避免报错
      if (chartTotal.value) {
        chartTotal.value.destroy();
      }
      if (chartCategory.value) {
        chartCategory.value.destroy();
      }
      if (chartFlavor.value) {
        chartFlavor.value.destroy();
      }
      if (chartCountry.value) {
        chartCountry.value.destroy();
      }
      if (chartActivity.value) {
        chartActivity.value.destroy();
      }
  
      // 初始化总和柱状图
      chartTotal.value = new Chart(ctxTotal, {
        type: 'bar',
        data: {
          labels: chartData.value.labels,
          datasets: chartData.value.totalDataset,
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                precision: 0,
              },
            },
          },
          plugins: {
            legend: {
              display: true,
            },
            title: {
              display: true,
              text: '总和显示柱状图',
            },
          },
        },
      });
  
      // 初始化按分类显示的柱状图
      chartCategory.value = new Chart(ctxCategory, {
        type: 'bar',
        data: {
          labels: chartData.value.labels,
          datasets: chartData.value.categoryDatasets,
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                precision: 0,
              },
            },
          },
          plugins: {
            legend: {
              display: true,
            },
            title: {
              display: true,
              text: '按分类显示柱状图',
            },
          },
        },
      });
  
      // 初始化 Flavor 占比折线图
      chartFlavor.value = new Chart(ctxFlavor, {
        type: 'line',
        data: {
          labels: chartData.value.labels,
          datasets: chartData.value.flavorDatasets,
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                precision: 0,
              },
            },
          },
          plugins: {
            legend: {
              display: true,
            },
            title: {
              display: true,
              text: 'Flavor 显示占比折线图',
            },
          },
        },
      });
  
      // 初始化 Country 占比折线图
      chartCountry.value = new Chart(ctxCountry, {
        type: 'line',
        data: {
          labels: chartData.value.labels,
          datasets: chartData.value.countryDatasets,
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                precision: 0,
              },
            },
          },
          plugins: {
            legend: {
              display: true,
            },
            title: {
              display: true,
              text: 'Country 显示占比折线图',
            },
          },
        },
      });
  
      // 初始化 Activity 折线图
      chartActivity.value = new Chart(ctxActivity, {
        type: 'line',
        data: {
          labels: chartData.value.labels,
          datasets: chartData.value.activityDatasets,
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                precision: 0,
              },
            },
          },
          plugins: {
            legend: {
              display: true,
            },
            title: {
              display: true,
              text: '分享/复制/下载数量折线图',
            },
          },
        },
      });
    }
  };
  
  // 在组件卸载时销毁图表实例，防止内存泄漏
  onUnmounted(() => {
    if (chartTotal.value) {
      chartTotal.value.destroy();
    }
    if (chartCategory.value) {
      chartCategory.value.destroy();
    }
    if (chartFlavor.value) {
      chartFlavor.value.destroy();
    }
    if (chartCountry.value) {
      chartCountry.value.destroy();
    }
    if (chartActivity.value) {
      chartActivity.value.destroy();
    }
  });
  </script>
  
  <style scoped>
  /* 你的样式可以在这里添加 */
  </style>
  