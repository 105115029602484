<template>
    <div class="max-w-4xl mx-auto p-4">
        <h1 class="text-3xl font-bold mb-6">Discussions</h1>
        <div class="mb-8 bg-white shadow-md rounded-lg p-6">
            <input v-model="newTopic.username" placeholder="Your name" class="w-full mb-4 p-2 border rounded" />
            <textarea v-model="newTopic.content" placeholder="Start a new discussion..."
                class="w-full mb-4 p-2 border rounded h-24"></textarea>
            <button @click="createTopic"
                class="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300">
                Start New Discussion
            </button>
        </div>
        <ul>
            <li v-for="topic in topics" :key="topic.id" @click="goToDetail(topic.id)"
                class="mb-4 bg-white shadow-md rounded-lg p-4 cursor-pointer hover:shadow-lg transition duration-300">
                <h3 class="text-xl font-semibold mb-2">{{ topic.topic }}</h3>
                <p class="text-sm text-gray-600">Created by: {{ topic.creator }}</p>
                <p class="text-sm text-gray-600">Created at: {{ new Date(topic.created_at).toLocaleString() }}</p>
                <div class="mt-2 text-xs text-gray-500">
                    <p>Total Tokens: {{ topic.total_tokens }}</p>
                    <p>Prompt Tokens: {{ topic.total_prompt_tokens }}</p>
                    <p>Completion Tokens: {{ topic.total_completion_tokens }}</p>
                    <p>Reasoning Tokens: {{ topic.total_reasoning_tokens }}</p>
                </div>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const topics = ref([]);
const newTopic = ref({ username: '', content: '' });

const fetchTopics = async () => {
    try {
        const response = await fetch('/api/discuss/list');
        const data = await response.json();
        if (data.code === 1) {
            topics.value = data.data;
        } else {
            console.error('Failed to fetch topics:', data.message);
        }
    } catch (error) {
        console.error('Error fetching topics:', error);
    }
};

const createTopic = async () => {
    try {
        const response = await fetch('/api/discuss/start', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(newTopic.value)
        });
        const data = await response.json();
        if (data.code === 1) {
            await fetchTopics();
            newTopic.value = { username: '', content: '' };
            // 创建成功后直接跳转到新创建的讨论详情页
            router.push(`/discuss/${data.discussId}`);
        } else {
            console.error('Failed to create topic:', data.message);
        }
    } catch (error) {
        console.error('Error creating topic:', error);
    }
};

const goToDetail = (id) => {
    router.push(`/discuss/${id}`);
};

onMounted(fetchTopics);
</script>