<template>
  <div class="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 flex items-center justify-center p-4">
    <div class="w-full max-w-3xl">
      <div class="bg-white bg-opacity-10 backdrop-blur-lg rounded-3xl overflow-hidden shadow-2xl">
        <div class="p-8">
          <h1 class="text-4xl font-extrabold text-center mb-2 text-white">AI Vision Studio</h1>
          <p class="text-center text-gray-300 mb-8">上传图片，输入提示词，使用不同AI模型生成回复！</p>

          <div class="space-y-6">
            <!-- 上传按钮和图片预览 -->
            <div class="flex flex-col md:flex-row items-center gap-6">
              <div class="w-full md:w-1/2">
                <div class="relative w-full aspect-square rounded-2xl overflow-hidden border-4 border-indigo-500 group transition-all duration-300 ease-in-out hover:border-indigo-400">
                  <img v-if="!imageUrl" src="https://via.placeholder.com/400" alt="图片占位符" class="w-full h-full object-cover">
                  <img v-else :src="imageUrl" alt="上传图片预览" class="w-full h-full object-cover">
                  <div class="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <button @click="triggerFileInput" class="bg-indigo-600 text-white px-5 py-3 rounded-full hover:bg-indigo-700 transition-all duration-300 ease-in-out transform hover:scale-105 text-lg">
                      {{ imageUrl ? '更换图片' : '上传图片' }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="w-full md:w-1/2 space-y-4">
                <textarea v-model="userPrompt" placeholder="输入提示词 (可选)" rows="4"
                  class="w-full p-4 bg-gray-800 text-white border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 placeholder-gray-500"></textarea>
                <button @click="getAllComments" v-if="imageUrl"
                  class="w-full bg-indigo-600 text-white px-6 py-3 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out transform hover:scale-105">
                  生成所有模型回复
                </button>
              </div>
            </div>

            <input ref="fileInput" type="file" @change="onFileChange" accept="image/*" class="hidden">

            <!-- 模型按钮 -->
            <div v-if="imageUrl" class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <button v-for="model in models" :key="model.name" @click="getComment(model.endpoint)"
                class="bg-gray-800 text-white px-6 py-3 rounded-lg hover:bg-gray-700 transition-all duration-300 ease-in-out transform hover:scale-105 flex items-center justify-center">
                <component :is="getModelIcon(model.name)" class="w-5 h-5 mr-2" />
                {{ model.name }}
              </button>
            </div>

            <!-- 加载中动画 -->
            <div v-if="isLoading" class="flex justify-center items-center space-x-2">
              <div class="w-4 h-4 bg-indigo-500 rounded-full animate-bounce"></div>
              <div class="w-4 h-4 bg-indigo-500 rounded-full animate-bounce" style="animation-delay: 0.1s"></div>
              <div class="w-4 h-4 bg-indigo-500 rounded-full animate-bounce" style="animation-delay: 0.2s"></div>
            </div>

            <!-- 模型回复显示 -->
            <div v-if="Object.keys(results).length" class="space-y-4">
              <div v-for="(result, model) in results" :key="model"
                class="bg-gray-800 p-6 rounded-lg shadow-inner">
                <h3 class="text-indigo-400 text-lg font-medium mb-2 flex items-center">
                  <component :is="getModelIcon(model)" class="w-5 h-5 mr-2" />
                  {{ model }} 回复:
                </h3>
                <p class="text-white text-lg">{{ result }}</p>
              </div>
            </div>
          </div>

          <!-- 保存整个卡片为图片的按钮 -->
          <div class="mt-8 flex justify-center">
            <button @click="downloadAppAsImage"
              class="bg-green-600 text-white px-6 py-3 rounded-full hover:bg-green-700 transition-all duration-300 ease-in-out transform hover:scale-105 flex items-center">
              <DownloadIcon class="w-5 h-5 mr-2" />
              保存整个卡片为图片
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import domtoimage from 'dom-to-image';
import { 
  ImageIcon, 
  BrainCircuitIcon, 
  SparklesIcon, 
  ApertureIcon, 
  LayersIcon,
  DownloadIcon
} from 'lucide-vue-next';

const imageUrl = ref(null);
const base64Image = ref('');
const mimeType = ref('');
const userPrompt = ref('');
const isLoading = ref(false);
const results = ref({});
const fileInput = ref(null);

const models = ref([
  { name: 'GPT-4o', endpoint: '/api/aivision/gpt-4o' },
  { name: 'GPT-4o-Structure', endpoint: '/api/aivision/gpt-4o-structure' },
  { name: 'Gemini 1.5 Pro', endpoint: '/api/aivision/gemini-1.5-pro' },
  { name: 'Gemini 1.5 Pro 0827', endpoint: '/api/aivision/gemini-1.5-pro-exp-0827' },
  { name: 'Claude-Sonnet 3.5', endpoint: '/api/aivision/claude-sonnet-3.5' },
  { name: 'Molmo-7b', endpoint: '/api/aivision/molmo-7b' },
]);

const getModelIcon = (modelName) => {
  switch (modelName) {
    case 'GPT-4o':
    case 'GPT-4o-Structure':
      return BrainCircuitIcon;
    case 'Gemini 1.5 Pro':
    case 'Gemini 1.5 Pro 0827':
      return SparklesIcon;
    case 'Claude-Sonnet 3.5':
      return ApertureIcon;
    case 'Molmo-7b':
      return LayersIcon;
    default:
      return ImageIcon;
  }
};

const triggerFileInput = () => {
  fileInput.value.click();
};

const onFileChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    mimeType.value = file.type;
    const reader = new FileReader();
    reader.onload = (e) => {
      base64Image.value = e.target.result.split(',')[1];
      imageUrl.value = e.target.result;
    };
    reader.readAsDataURL(file);
  }
};

const fetchModelResponse = async (endpoint) => {
  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        imageBase64: base64Image.value,
        mimeType: mimeType.value,
        prompt: userPrompt.value
      })
    });
    const data = await response.json();
    return data.aiComment;
  } catch (error) {
    console.error('Error:', error);
    return '生成失败，请稍后重试。';
  }
};

const getComment = async (endpoint) => {
  if (!base64Image.value || !mimeType.value) {
    alert('请先上传一张图片并确保 MIME 类型存在！');
    return;
  }

  isLoading.value = true;
  const result = await fetchModelResponse(endpoint);
  results.value[models.value.find(m => m.endpoint === endpoint).name] = result;
  isLoading.value = false;
};

const getAllComments = async () => {
  if (!base64Image.value || !mimeType.value) {
    alert('请先上传一张图片并确保 MIME 类型存在！');
    return;
  }

  isLoading.value = true;
  for (const model of models.value) {
    const result = await fetchModelResponse(model.endpoint);
    results.value[model.name] = result;
  }
  isLoading.value = false;
};

const downloadAppAsImage = () => {
  const appElement = document.getElementById('app');
  domtoimage.toPng(appElement)
    .then((dataUrl) => {
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = 'ai-vision-studio.png';
      link.click();
    })
    .catch((error) => {
      console.error('图片生成失败:', error);
    });
};
</script>

<style>
/* 可以添加全局样式 */
body {
  font-family: 'Inter', sans-serif;
}
</style>