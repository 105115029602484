// src/utils/request.js

export async function request(url, method = 'GET', data = null, auth = true) {
    const headers = {
      'Content-Type': 'application/json',
    };
  
    // 如果需要认证，并且存在 token，则将其添加到请求头中
    if (auth && localStorage.getItem('token')) {
      headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    }
  
    const options = {
      method,
      headers,
    };
  
    if (data) {
      options.body = JSON.stringify(data);
    }
  
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Request failed', error);
      throw error;
    }
  }
  
  // 简化的 GET 请求封装
  export function get(url, auth = true) {
    return request(url, 'GET', null, auth);
  }
  
  // 简化的 POST 请求封装
  export function post(url, data, auth = true) {
    return request(url, 'POST', data, auth);
  }
  

  // 定义可用的模型
export const models = [
  'gpt-3.5-turbo-1106',
  'gpt-4-1106-preview',
  'gpt-4-0125-preview',
  'gpt-4o-latest',
  'gpt-4o',
  'gpt-4o-2024-08-06',
  'gpt-4o-mini',
  'gpt-3.5-turbo-0125',
  'claude-3-5-sonnet-20240620',
  'claude-3-opus-20240229',
  'claude-3-sonnet-20240229',
  'claude-3-haiku-20240307'
];



/**
 * 从URL的哈希部分或查询字符串中获取指定参数的值
 * @param {string} name - 要获取的参数名
 * @returns {string|null} 参数值，如果参数不存在则返回null
 */
export function getHashParam(name) {
  // 首先尝试从哈希中获取
  const hash = window.location.hash.substring(1);
  const params = new URLSearchParams(hash);
  if (params.has(name)) {
      return params.get(name);
  }

  // 如果哈希中没有，则尝试从查询字符串获取
  const query = window.location.search.substring(1);
  const queryParams = new URLSearchParams(query);
  if (queryParams.has(name)) {
      return queryParams.get(name);
  }

  // 如果都没有找到，返回null
  return null;
}


export function extractJSONFromAIOutput(content) {
  // 使用正则表达式移除 Markdown 中的 ```json 和 ```，并去除多余的空白字符
  const cleanedContent = content.replace(/```json|```/g, '').trim();
  return cleanedContent;
}
