<template>
    <div>
        <h1>各平台日活跃用户比例</h1>
        <canvas ref="lineChart"></canvas>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const lineChart = ref(null);
const chartInstance = ref(null);

const fetchData = async () => {
    try {
        const response = await fetch('/api/analyzer/active'); // 替换为你的新接口路径
        const data = await response.json();
        renderChart(data.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
const renderChart = (data) => {
    const labels = [...new Set(data.map(item => item.activity_date))];
    const appNames = [...new Set(data.map(item => item.app_name))];

    const datasets = appNames.map(appName => {
        const proportionGe2 = data
            .filter(item => item.app_name === appName)
            .map(item => item.proportion_ge_2); // 获取2次活跃用户比例
        
        const proportionGe5 = data
            .filter(item => item.app_name === appName)
            .map(item => item.proportion_ge_5);
        
        const proportionGe10 = data
            .filter(item => item.app_name === appName)
            .map(item => item.proportion_ge_10);

        return [
            {
                label: `${appName} 2 次活跃用户比例`, // 新增图例
                data: proportionGe2,
                borderColor: 'rgba(255, 206, 86, 1)', // 设置不同颜色
                fill: false,
            },
            {
                label: `${appName} 5 次活跃用户比例`,
                data: proportionGe5,
                borderColor: 'rgba(75, 192, 192, 1)',
                fill: false,
            },
            {
                label: `${appName} 10 次活跃用户比例`,
                data: proportionGe10,
                borderColor: 'rgba(153, 102, 255, 1)',
                fill: false,
            },
        ];
    }).flat();

    const ctx = lineChart.value.getContext('2d');
    if (chartInstance.value) {
        chartInstance.value.destroy();
    }

    chartInstance.value = new Chart(ctx, {
        type: 'line',
        data: {
            labels,
            datasets,
        },
        options: {
            responsive: true,
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        callback: (value) => `${value}%`,
                    },
                },
            },
        },
    });
};


onMounted(() => {
    fetchData();
});
</script>

<style scoped>
/* 添加你的样式 */
</style>
