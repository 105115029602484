
// 定义工具
export const tools = [
    {
        "type": "function",
        "function": {
            "description": "查看指定路径下的文件和文件夹列表。",
            "name": "getFiles",
            "parameters": {
                "type": "object",
                "properties": {
                    "path": {
                        "type": "string",
                        "description": "文件或文件夹路径，默认为根目录。",
                        "default": ""
                    }
                },
                "required": ["path"]
            }
        }
    },
    {
        "type": "function",
        "function": {
            "description": "打开指定路径下的文件并获取其内容。",
            "name": "openFile",
            "parameters": {
                "type": "object",
                "properties": {
                    "filePath": {
                        "type": "string",
                        "description": "要打开的文件的路径。"
                    }
                },
                "required": ["filePath"]
            }
        }
    },
    {
        "type": "function",
        "function": {
            "description": "在仓库中创建一个新文件。",
            "name": "createNewFile",
            "parameters": {
                "type": "object",
                "properties": {
                    "filePath": {
                        "type": "string",
                        "description": "要创建的新文件的路径（包括文件名）。"
                    },
                    "content": {
                        "type": "string",
                        "description": "新文件的内容。"
                    },
                    "message": {
                        "type": "string",
                        "description": "提交时的描述信息。",
                        "default": "Add new file"
                    }
                },
                "required": ["filePath", "content"]
            }
        }
    },
    {
        "type": "function",
        "function": {
            "description": "编辑仓库中的现有文件。",
            "name": "editFile",
            "parameters": {
                "type": "object",
                "properties": {
                    "filePath": {
                        "type": "string",
                        "description": "要编辑的文件的路径。"
                    },
                    "content": {
                        "type": "string",
                        "description": "更新后的文件内容。"
                    },
                    "message": {
                        "type": "string",
                        "description": "提交时的描述信息。",
                        "default": "Edit file"
                    }
                },
                "required": ["filePath", "content", "message"]
            }
        }
    },
    // {
    //     "type": "function",
    //     "function": {
    //         "description": "查看仓库的提交记录。",
    //         "name": "getCommits",
    //         "parameters": {
    //             "type": "object",
    //             "properties": {},
    //             "required": []
    //         }
    //     }
    // },
    // {
    //     "type": "function",
    //     "function": {
    //         "description": "在指定目录中搜索文件名。通过传递 1 表示递归搜索，传递 0 表示非递归搜索。",
    //         "name": "searchFile",
    //         "parameters": {
    //             "type": "object",
    //             "properties": {
    //                 "folderPath": {
    //                     "type": "string",
    //                     "description": "开始搜索的目录路径。"
    //                 },
    //                 "fileName": {
    //                     "type": "string",
    //                     "description": "要搜索的文件名。"
    //                 },
    //                 "recursive": {
    //                     "type": "number",
    //                     "description": "递归搜索为 1，非递归搜索为 0。"
    //                 }
    //             },
    //             "required": ["folderPath", "fileName", "recursive"]
    //         }
    //     }
    // },
    // {
    //     "type": "function",
    //     "function": {
    //         "description": "在 GitHub 仓库中搜索包含特定内容的文件。",
    //         "name": "searchContent",
    //         "parameters": {
    //             "type": "object",
    //             "properties": {
    //                 "searchTerm": {
    //                     "type": "string",
    //                     "description": "要在文件内容中搜索的关键词或短语。"
    //                 }
    //             },
    //             "required": ["searchTerm"]
    //         }
    //     }
    // }
]