<template>
  <div class="flex h-screen">
    <!-- 左边带有两个选项卡的界面 -->
    <div class="flex-1 flex flex-col border-r border-gray-300 overflow-y-auto">
      <!-- 选项卡 -->
      <div class="flex border-b">
        <button :class="tabClass('dailyReport')" @click="activeTab = 'dailyReport'">日报</button>
        <button :class="tabClass('subscription')" @click="activeTab = 'subscription'">订阅</button>
      </div>
      <!-- 选项卡内容 -->
      <div class="flex-1 p-6 bg-gray-50">
        <div v-if="activeTab === 'dailyReport'">
          <!-- 日报内容 -->
          <ul class="timeline">
            <li v-for="item in dailyReports" :key="item.id" class="mb-4">
              <div class="flex">
                <div class="w-1/4 text-right pr-4">
                  <span class="text-gray-500">{{ item.date }}</span>
                </div>
                <div class="w-3/4">
                  <h3 class="text-lg font-bold">{{ item.title }}</h3>
                  <!-- 子标题列表 -->
                  <ul class="list-disc pl-5 marker:text-gray-500">
                    <li v-for="sub in item.subtitles" :key="sub.url" class="text-sm text-gray-600">
                      <a :href="sub.url" target="_blank">{{ sub.title }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>


        </div>
        <div v-else-if="activeTab === 'subscription'">
          <!-- 订阅内容 -->
          <div>
            <!-- 文本框 1：用于更新 system 提示词 -->
            <p class="text-gray-500 mb-2">系统提示词</p>
            <textarea v-model="systemMessage" placeholder="输入系统提示词"
              class="w-full p-2 border border-gray-300 rounded mb-2" rows="3"></textarea>

            <!-- 文本框 2：用于发送用户消息 -->

            <p class="text-gray-500 mb-2">用户消息</p>
            <textarea v-model="userMessage" placeholder="输入用户消息" class="w-full p-2 border border-gray-300 rounded mb-2"
              rows="3"></textarea>
            <!-- 执行按钮 -->
            <button @click="runTaskMessages" class="bg-blue-500 text-white px-4 py-2 rounded">运行</button>
          </div>
        </div>
      </div>
    </div>

    <!-- 右边模型选择和 Chat 组件 -->
    <div class="w-[400px] flex flex-col">
      <!-- 模型选择下拉菜单 -->
      <div class="flex justify-center mt-2">
        <select v-model="selectedModel" class="p-2 text-sm border border-gray-300 rounded">
          <option v-for="model in models" :key="model" :value="model">
            {{ model }}
          </option>
        </select>
      </div>
      <!-- Chat 组件 -->
      <chat :model="selectedModel" :tools="tools" @run-tool="runTool" ref="Ai" />
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import chat from '../components/AI/chat/_old/openaiChatStream.vue';
import { post } from '../utils';

// 定义可用的模型
const models = [
  'gpt-3.5-turbo-1106',
  'gpt-4-1106-preview',
  'gpt-4-0125-preview',
  'gpt-4o',
  'gpt-4o-2024-08-06',
  'gpt-4o-mini',
  'gpt-3.5-turbo-0125',
  'claude-3-5-sonnet-20240620',
  'claude-3-opus-20240229',
  'claude-3-sonnet-20240229',
  'claude-3-haiku-20240307'
];

// 默认选中的模型,将会通过props传递给子组件
const selectedModel = ref('gpt-4o-2024-08-06');

// 定义工具列表,将会通过props传递给子组件
const tools = ref([
  {
    type: 'function',
    function: {
      description: '通过Google搜索获取指定查询的结果',
      name: 'getGoogleSearch',
      parameters: {
        type: 'object',
        properties: {
          query: {
            type: 'string',
            description: '要搜索的关键词或查询字符串',
          },
        },
        required: ['query'],
      },
    },
  },
  {
    type: 'function',
    function: {
      description: '解析指定URL并获取其内容',
      name: 'getUrl',
      parameters: {
        type: 'object',
        properties: {
          url: {
            type: 'string',
            description: '要解析的网页的完整URL地址',
          },
        },
        required: ['url'],
      },
    },
  },
  {
    type: "function",
    function: {
      name: "report",
      description: "从新闻文章中提取主标题和子标题及其对应的URL。",
      strict: true,
      parameters: {
        type: "object",
        properties: {
          main_title: {
            type: "string",
            description: "新闻文章的主标题。"
          },
          sub_titles: {
            type: "array",
            items: {
              type: "object",
              properties: {
                title: {
                  type: "string",
                  description: "新闻文章中的子标题或段落标题。"
                },
                url: {
                  type: "string",
                  description: "与子标题相关的URL。"
                }
              },
              required: ["title", "url"],
              additionalProperties: false
            }
          }
        },
        required: ["main_title", "sub_titles"],
        additionalProperties: false
      }
    }
  }
]);

// 获取子组件实例
const Ai = ref(null);

// 处理子组件发出的 run-tool 事件
const runTool = async (toolCalls) => {
  let toolMessagesList = [];

  for (const item of toolCalls) {
    let { id, function: func } = item;
    let { name, arguments: args } = func;
    let funContent = '';

    // 将函数名映射到相应的 API 路径
    const apiMapping = {
      getGoogleSearch: '/api/assistant/tools/search',
      getUrl: '/api/assistant/tools/url',
    };

    try {
      // 执行工具调用
      try {
        // 特殊处理report工具，直接打印内容，不调用API
        if (name === 'report') {
          const parsedArgs = JSON.parse(args);
          const { main_title, sub_titles } = parsedArgs;

          // 将返回结果添加到 dailyReports 中
          dailyReports.push({
            id: dailyReports.length + 1, // 自动生成id
            date: new Date().toISOString().split('T')[0], // 使用当前日期
            title: main_title,
            subtitles: sub_titles.map(sub => ({ title: sub.title, url: sub.url })) // 将子标题和URL作为数组对象存储
          });

          funContent = `汇报已显示在界面中`;

        } else {

          // 根据函数名获取 API 路径
          const apiPath = apiMapping[name];

          if (!apiPath) {
            throw new Error(`未知的函数名: ${name}`);
          }

          // 执行工具调用
          const response = await post(apiPath, JSON.parse(args));
          funContent = JSON.stringify(response);
        }
      } catch (error) {
        funContent = `执行 ${name} 时出错: ${error.message}`;
      }
    } catch (error) {
      funContent = `执行 ${name} 时出错: ${error.message}`;
    }

    toolMessagesList.push({
      tool_call_id: id,
      role: 'tool',
      name: name,
      content: funContent,
      expanded: false,
    });
  }

  // 将工具响应消息传回子组件
  if (Ai.value && typeof Ai.value.addToolMessages === 'function') {
    Ai.value.addToolMessages(toolMessagesList);
  } else {
    console.error('无法访问子组件的方法 addToolMessages');
  }
};

// 选项卡
const activeTab = ref('dailyReport'); // 或者 'subscription'

// 选项卡样式函数
const tabClass = (tab) => {
  return [
    'flex-1 text-center py-2 cursor-pointer',
    activeTab.value === tab ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-600',
  ];
};

// 日报数据
const dailyReports = reactive([]);


// 文本框内容
const systemMessage = ref(`你是一个智能助手，负责为用户制作简洁的新闻日报。你的任务是通过搜索引擎和URL阅读器，收集与用户提供的关键词相关的新闻信息。

搜索技巧与扩展搜索：

关键词使用：只使用核心关键词进行搜索，不附加日期，确保覆盖更广的时间范围。
关键词精准性：选择简短且相关的关键词，避免过于宽泛或模糊。
监控搜索结果：关注搜索结果中的重要新闻、趋势或重大事件，识别高影响力内容。
扩展搜索：发现重要内容时，提取相关人物、事件、地点或机构作为新关键词进行扩展搜索。
信息不足时扩展：如信息不足，增加关联词、同义词或细化主题，扩大搜索范围。
聚焦深层次信息：扩展搜索时关注深层次信息，确保日报涵盖全面、最新的动态。
日报撰写与输出要求：

新闻筛选：优先提取最具影响力、最重要的新闻内容。
主标题：突出当天最亮点的新闻。
内容结构：简明扼要，按主题或来源分段，不冗长、不重复。
重点信息提炼：聚焦核心事实、关键数据和事件进展。
输出要求：使用report工具输出日报。
汇报完成：日报输出后，简洁说明“汇报完毕”即可。
扩展搜索：如信息不足，扩展搜索以确保内容完整。`);

// 用于更新 system 提示词的内容
const userMessage = ref('openai 的新闻'); // 用于发送用户消息的内容

// 同时运行两个输入框的内容：更新 system 消息和发送用户消息
const runTaskMessages = () => {
  if (Ai.value) {
    // 更新 system 提示词
    if (systemMessage.value.trim()) {
      Ai.value.updateSystemMessage(systemMessage.value);
    }

    // 发送用户消息
    if (userMessage.value.trim()) {
      Ai.value.sendStartMessage(userMessage.value);
    }
  }
};
</script>
