<template>
    <div class="max-w-4xl mx-auto p-4" v-if="discussDetail">
      <!-- <h1 class="text-3xl font-bold mb-4">{{ discussDetail.topic }}</h1> -->
      <p class="text-sm text-gray-600 mb-1">Created by: {{ discussDetail.creator }}</p>
      <p class="text-sm text-gray-600 mb-2">Created at: {{ new Date(discussDetail.created_at).toLocaleString() }}</p>
      <div class="mb-4 p-2 bg-gray-100 rounded">
        <h3 class="font-semibold">Total Usage:</h3>
        <p>Total Tokens: {{ discussDetail.total_tokens }}</p>
        <p>Prompt Tokens: {{ discussDetail.total_prompt_tokens }}</p>
        <p>Completion Tokens: {{ discussDetail.total_completion_tokens }}</p>
        <p>Reasoning Tokens: {{ discussDetail.total_reasoning_tokens }}</p>
      </div>
      <div class="space-y-4 mb-8">
        <div v-for="message in discussDetail.messages" :key="message.id" 
             class="bg-white shadow-md rounded-lg p-4">
          <p class="font-semibold mb-1">{{ message.username }} <span class="text-sm text-gray-500">({{ message.role }})</span></p>
          <!-- <p class="mb-2">{{ message.content }}</p> -->
          <div v-html="markdown(message.content)"></div>

          <p class="text-xs text-gray-500">{{ new Date(message.created_at).toLocaleString() }}</p>
          <div v-if="message.role === 'ai'" class="mt-2 text-xs text-gray-500">
            <p>Total Tokens: {{ message.total_tokens }}</p>
            <p>Prompt Tokens: {{ message.prompt_tokens }}</p>
            <p>Completion Tokens: {{ message.completion_tokens }}</p>
            <p>Reasoning Tokens: {{ message.reasoning_tokens }}</p>
          </div>
        </div>
      </div>
      <div class="bg-white shadow-md rounded-lg p-6">
        <input v-model="newMessage.username" placeholder="Your name" class="w-full mb-4 p-2 border rounded" />
        <textarea v-model="newMessage.content" placeholder="Your message" class="w-full mb-4 p-2 border rounded h-24"></textarea>
        <button @click="sendMessage" class="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300">
          Send Message
        </button>
      </div>
    </div>
  </template>



  <script setup>
  import { ref, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { marked } from 'marked';


  const route = useRoute();
  const discussDetail = ref(null);
  const newMessage = ref({ username: '', content: '' });
  
  const fetchDiscussDetail = async () => {
    try {
      const response = await fetch(`/api/discuss/detail?id=${route.params.id}`);
      const data = await response.json();
      if (data.code === 1) {
        discussDetail.value = data.data;
      } else {
        console.error('Failed to fetch discuss detail:', data.message);
      }
    } catch (error) {
      console.error('Error fetching discuss detail:', error);
    }
  };
  
  const sendMessage = async () => {
  try {
    const response = await fetch('/api/discuss/message', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...newMessage.value,
        discuss_id: route.params.id,
        role: 'participant'
      })
    });
    const data = await response.json();
    if (data.code === 1) {
      discussDetail.value.messages.push({
        id: data.userMessageId,
        username: newMessage.value.username,
        content: newMessage.value.content,
        role: 'participant',
        created_at: new Date().toISOString()
      });
      discussDetail.value.messages.push({
        id: data.aiMessageId,
        username: 'AI Assistant',
        content: data.aiResponse.content,
        role: 'ai',
        created_at: new Date().toISOString(),
        ...data.aiResponse.usage
      });
      // 更新讨论的总用量
      discussDetail.value.total_tokens += data.aiResponse.usage.total_tokens;
      discussDetail.value.total_prompt_tokens += data.aiResponse.usage.prompt_tokens;
      discussDetail.value.total_completion_tokens += data.aiResponse.usage.completion_tokens;
      discussDetail.value.total_reasoning_tokens += data.aiResponse.usage.reasoning_tokens;
      newMessage.value = { username: '', content: '' };
    } else {
      console.error('Failed to send message:', data.message);
    }
  } catch (error) {
    console.error('Error sending message:', error);
  }
};

const markdown = (content) => {
  return marked(content);
};

  onMounted(fetchDiscussDetail);
  </script>