<template>
  <div class="flex h-screen">
    <!-- 左边包含 SQL 查询 -->
    <div class="flex-1 flex flex-col border-r border-gray-300 overflow-y-auto">
      <div class="flex-1 p-6 bg-gray-50">
        <div class="flex justify-between items-center mb-4">
          <h1 class="text-3xl font-bold">数据可视化 - 自定义SQL查询</h1>
          <button @click="startSync" :disabled="isSyncing"
                  class="bg-green-500 text-white px-6 py-3 rounded hover:bg-green-600 disabled:opacity-50">
            {{ isSyncing ? '同步中...' : '同步数据' }}
          </button>
        </div>

        <!-- SQL 查询表单 -->
        <form @submit.prevent="executeSQL" class="bg-white p-6 shadow-md rounded mb-6">
          <div>
            <label class="block text-lg font-medium text-gray-700 mb-2">SQL 查询：</label>
            <textarea v-model="sqlQuery" rows="5" class="w-full p-2 border border-gray-300 rounded"
                      placeholder="请输入您的SQL查询语句"></textarea>
          </div>

          <div class="mt-4 flex items-center">
            <button type="submit" class="bg-green-500 text-white px-6 py-2 rounded hover:bg-green-600">执行查询</button>
            <button @click="openSaveModal" type="button" class="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600 ml-4">
              保存查询
            </button>
            <div v-if="loading" class="ml-4 text-blue-500">查询中...</div>
          </div>
        </form>

        <!-- 查询结果显示 -->
        <div v-if="sqlResults && sqlResults.length > 0" class="bg-white p-6 shadow-md rounded mb-6 overflow-x-auto">
          <h2 class="text-2xl font-bold mb-4">查询结果</h2>
          <table class="min-w-full bg-white">
            <thead>
              <tr>
                <th v-for="(header, index) in sqlHeaders" :key="index" class="px-4 py-2 border">{{ header }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, rowIndex) in sqlResults" :key="rowIndex">
                <td v-for="(cell, cellIndex) in row" :key="cellIndex" class="px-4 py-2 border">{{ cell }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 保存的 SQL 查询列表 -->
        <div v-if="savedSQLQueries.length > 0" class="bg-white p-6 shadow-md rounded mb-6 overflow-x-auto">
          <h2 class="text-2xl font-bold mb-4">已保存的查询</h2>
          <ul>
            <li v-for="sql in savedSQLQueries" :key="sql.id" @click="fillQuery(sql.query)"
                class="cursor-pointer text-blue-500 underline mb-2 hover:bg-gray-100 p-2">
              {{ sql.title }}
            </li>
          </ul>
        </div>

        <!-- 错误信息显示 -->
        <div v-if="sqlError" class="bg-white p-6 shadow-md rounded mb-6 text-red-500">
          <h2 class="text-2xl font-bold mb-4">错误信息</h2>
          <p>{{ sqlError }}</p>
        </div>
      </div>
    </div>

    <!-- 右边模型选择和 Chat 组件 -->
    <div class="w-[400px] flex flex-col">
      <!-- 模型选择下拉菜单 -->
      <div class="flex justify-center mt-2">
        <select v-model="selectedModel" class="p-2 text-sm border border-gray-300 rounded">
          <option v-for="model in models" :key="model" :value="model">
            {{ model }}
          </option>
        </select>
      </div>

      <!-- Chat 组件 -->
      <chat :model="selectedModel" :prompt="prompt" :tools="tools" @run-tool="runTool" ref="Ai" />
    </div>

    <!-- 保存 SQL 的模态框 -->
    <div v-if="showSaveModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div class="bg-white p-6 rounded shadow-lg">
        <h3 class="text-2xl font-bold mb-4">保存SQL查询</h3>
        <input v-model="newSQLTitle" type="text" placeholder="输入查询标题" class="w-full p-2 border mb-4">
        <div class="flex justify-end">
          <button @click="saveSQLQuery" class="bg-green-500 text-white px-4 py-2 rounded mr-2">保存</button>
          <button @click="closeSaveModal" class="bg-red-500 text-white px-4 py-2 rounded">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import chat from '../../components/AI/chat/_old/openaiChatStream.vue';
// import { post } from '../../utils/request';

// 定义可用的模型
const models = [
  'gpt-3.5-turbo-1106',
  'gpt-4-1106-preview',
  'gpt-4-0125-preview',
  'gpt-4o',
  'gpt-4o-2024-08-06',
  'gpt-4o-mini',
  'gpt-3.5-turbo-0125',
  'claude-3-5-sonnet-20240620',
  'claude-3-opus-20240229',
  'claude-3-sonnet-20240229',
  'claude-3-haiku-20240307'
];

// 默认选中的模型
const selectedModel = ref('gpt-4o-mini');

// 保存 SQL 查询相关
const showSaveModal = ref(false);
const newSQLTitle = ref('');
const savedSQLQueries = ref([]);

// SQL 查询相关
const sqlQuery = ref('');
const sqlResults = ref([]);
const sqlHeaders = ref([]);
const sqlError = ref(null);
const loading = ref(false);

// 同步按钮的状态
const isSyncing = ref(false);

// 执行 SQL 查询的函数
const executeSQL = async () => {
  sqlError.value = null;
  sqlResults.value = null;
  sqlHeaders.value = [];

  if (!sqlQuery.value.trim()) {
    alert('请先输入SQL查询语句');
    return;
  }

  loading.value = true;

  try {
    const response = await fetch('/api/analyzer/sql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: sqlQuery.value })
    });

    const result = await response.json();

    if (response.ok) {
      if (result.data && result.data.length > 0) {
        sqlResults.value = result.data.map(row => Object.values(row));
        sqlHeaders.value = Object.keys(result.data[0]);
      } else {
        sqlResults.value = [];
        sqlHeaders.value = [];
        alert('查询成功，但没有返回数据。');
      }
    } else {
      sqlError.value = result.message || '查询失败';
    }
  } catch (error) {
    console.error('Error:', error);
    sqlError.value = '查询失败，请检查控制台日志。';
  } finally {
    loading.value = false;
  }
};

// 打开保存 SQL 查询的模态框
const openSaveModal = () => {
  showSaveModal.value = true;
};

// 关闭保存 SQL 查询的模态框
const closeSaveModal = () => {
  showSaveModal.value = false;
  newSQLTitle.value = '';
};

// 保存 SQL 查询
const saveSQLQuery = async () => {
  if (!newSQLTitle.value || !sqlQuery.value) {
    alert('请填写标题和查询语句');
    return;
  }

  try {
    const response = await fetch('/api/analyzer/sql/save', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ title: newSQLTitle.value, query: sqlQuery.value })
    });

    if (response.ok) {
      alert('SQL查询保存成功');
      closeSaveModal();
      fetchSavedSQLQueries();
    } else {
      alert('保存失败');
    }
  } catch (error) {
    console.error('Error saving SQL:', error);
    alert('保存过程中出错，请检查控制台日志。');
  }
};

// 获取保存的 SQL 查询列表
const fetchSavedSQLQueries = async () => {
  try {
    const response = await fetch('/api/analyzer/sql/list');
    const result = await response.json();
    if (response.ok) {
      savedSQLQueries.value = result.data;
    } else {
      alert('获取保存的 SQL 查询列表失败');
    }
  } catch (error) {
    console.error('Error fetching saved SQL queries:', error);
    alert('获取保存的 SQL 查询列表时出错，请检查控制台日志。');
  }
};

// 填充 SQL 到输入框
const fillQuery = (query) => {
  sqlQuery.value = query;
};

// 同步操作的函数
const startSync = async () => {
  if (isSyncing.value) return;  // 防止重复点击

  isSyncing.value = true;

  try {
    const response = await fetch('/api/analyzer/sync', {  // 替换为您的同步接口URL
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({})  // 发送空对象作为请求体
    });

    const result = await response.json();

    if (response.ok) {
      alert('数据同步完成！');
    } else {
      alert(`同步失败：${result.message}`);
    }
  } catch (error) {
    alert('同步过程中出现错误，请检查控制台日志。');
  } finally {
    isSyncing.value = false;
  }
};

// 初始化时获取已保存的 SQL
onMounted(fetchSavedSQLQueries);
</script>
