<template>
    <div class="flex flex-col h-screen bg-gray-50">
      <!-- 对话列表 -->
      <div class="flex-1 overflow-y-auto p-4 space-y-4">
        <!-- 场景入口 -->
        <div v-if="chatHistory.length === 0" class="space-y-4">
          <div v-for="(scenario, index) in scenarios" :key="index" 
               @click="selectScenario(scenario)"
               class="flex items-center p-4 bg-white rounded-lg shadow-sm hover:bg-gray-50 cursor-pointer transition-colors duration-150">
            <div class="flex-shrink-0 mr-4">
              <component :is="scenario.icon" class="w-8 h-8 text-blue-500" />
            </div>
            <div class="flex-grow">
              <h3 class="text-lg font-medium text-gray-900">{{ scenario.title }}</h3>
              <p class="text-sm text-gray-500">{{ scenario.description }}</p>
            </div>
          </div>
        </div>
        <!-- 对话消息 -->
        <div v-for="(message, index) in chatHistory" :key="index" 
             class="max-w-lg mx-auto p-3 rounded-lg" 
             :class="message.sender === 'user' ? 'bg-blue-100 ml-auto' : 'bg-white'">
          <p class="text-sm" :class="message.sender === 'user' ? 'text-blue-800' : 'text-gray-800'">
            {{ message.content }}
          </p>
          <img v-if="message.image" :src="message.image" alt="Uploaded image" class="mt-2 rounded-lg max-h-60 mx-auto">
        </div>
      </div>
  
      <!-- 底部输入区域 -->
      <div class="p-4 bg-white border-t border-gray-200">
        <div class="flex items-center space-x-4">
          <label for="image-upload" class="flex-shrink-0 cursor-pointer">
            <ImageIcon class="w-6 h-6 text-gray-400 hover:text-blue-500" />
            <input id="image-upload" type="file" class="hidden" @change="handleImageUpload" accept="image/*" />
          </label>
          <input
            v-model="userInput"
            type="text"
            placeholder="输入消息或上传图片..."
            class="flex-1 p-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            @keyup.enter="sendMessage()"
          />
          <button @click="sendMessage" class="flex-shrink-0">
            <SendIcon class="w-6 h-6 text-blue-500 hover:text-blue-600" />
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue'
  import { ImageIcon, FileTextIcon, EyeIcon, TagIcon, PaletteIcon, SendIcon } from 'lucide-vue-next'
  
  const userInput = ref('')
  const chatHistory = ref([])
  const currentImage = ref(null)
  
  const scenarios = [
    { 
      title: '生成文案', 
      description: '基于上传的图片生成吸引人的营销文案', 
      icon: FileTextIcon,
      action: '生成文案'
    },
    { 
      title: '解释图片', 
      description: '详细描述图片中的内容和场景', 
      icon: EyeIcon,
      action: '解释图片'
    },
    { 
      title: '识别物体', 
      description: '列出图片中的主要物体和元素', 
      icon: TagIcon,
      action: '识别物体'
    },
    { 
      title: '提取颜色', 
      description: '分析图片的主要颜色和色调', 
      icon: PaletteIcon,
      action: '提取颜色'
    },
  ]
  
  const handleImageUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        currentImage.value = e.target.result
        chatHistory.value.push({
          sender: 'user',
          content: '图片已上传',
          image: e.target.result
        })
      }
      reader.readAsDataURL(file)
    }
  }
  
  const selectScenario = (scenario) => {
    if (!currentImage.value) {
      alert('请先上传图片')
      return
    }
    sendMessage(scenario.action)
  }
  
  const sendMessage = (message = userInput.value) => {
    if (typeof message === 'string' && !message.trim() && !currentImage.value) return
  
    chatHistory.value.push({
      sender: 'user',
      content: message
    })
  
    // 模拟 AI 响应
    setTimeout(() => {
      const aiResponse = generateAIResponse(message)
      chatHistory.value.push({
        sender: 'ai',
        content: aiResponse
      })
    }, 1000)
  
    userInput.value = '' // 清空输入框内容
    if (message === currentImage.value) {
      currentImage.value = null // 如果消息是图片内容，则清空图片
    }
  }
  
  const generateAIResponse = (prompt) => {
    const responses = {
      '生成文案': '这张图片展示了一个美丽的自然景观，可以用作旅游广告或环保宣传的背景。它传达了宁静、和谐与自然之美的信息，让人不禁想要亲身体验这片绿色天堂。',
      '解释图片': '图片中是一片郁郁葱葱的森林，树木高大茂密，阳光透过树叶洒落在地面上，营造出一种宁静祥和的氛围。这样的场景让人联想到大自然的神奇和生命的活力，仿佛能听到鸟儿的鸣叫和微风拂过树叶的沙沙声。',
      '识别物体': '图中主要包含以下物体：1. 高大的树木，可能是针叶林或阔叶林；2. 茂密的树叶和树冠；3. 森林地面，可能覆盖着落叶或苔藓；4. 穿透树叶的阳光，形成光斑；5. 可能存在的森林小径或空地。这些元素共同构成了一个典型的森林生态系统场景。',
      '提取颜色': '主要颜色：1. 深绿色（树叶和树干的主色调）；2. 浅绿色（被阳光照亮的树叶）；3. 棕色（树干和地面）；4. 金黄色（穿透树叶的阳光）；5. 可能的浅蓝色（如果有可见的天空）。这种配色方案给人以清新、自然、生机勃勃的感觉，体现了森林的层次感和生命力。',
    }
  
    return responses[prompt] || `基于您的输入 "${prompt}"，AI 分析结果如下：这是一个自定义的分析结果，实际应用中应该调用 AI API 来生成相应的内容。该分析会考虑图片的内容、构图、色彩等多个方面，为您提供深入的见解。`
  }
  </script>
  