<template>
  <div class="flex flex-col">
    <!-- 添加模型选择下拉菜单 -->
    <div class="flex justify-center mt-3">
      <select v-model="selectedModel" class="p-2 text-sm border border-gray-300 rounded">
        <option v-for="model in models" :key="model" :value="model">
          {{ model }}
        </option>
      </select>
    </div>

    <!-- <chat :model="selectedModel" :prompt="prompt" :tools="tools" @run-tool="runTool" ref="Ai" /> -->
    <chat :model="selectedModel" :tools="tools" :appDataSync="appDataSync" :getAppData="getAppData" :runTool="runTool" ref="Ai" />

  </div>
</template>

<script setup>
import { ref } from 'vue';
// import chat from '../components/AI/chat/_old/openaiChatStream.vue';
import chat from '../components/AI/chat/openai/stream.vue';
import { post } from '../utils';

// 定义可用的模型
const models = [
  'gpt-3.5-turbo-1106',
  'gpt-4-1106-preview',
  'gpt-4-0125-preview',
  'gpt-4o',
  'gpt-4o-2024-08-06',
  'gpt-4o-mini',
  'gpt-3.5-turbo-0125',
  'claude-3-5-sonnet-20240620',
  'claude-3-opus-20240229',
  'claude-3-sonnet-20240229',
  'claude-3-haiku-20240307'
];

// const prompt =`你是一个有用的助手`

// 默认选中的模型
const selectedModel = ref('gpt-4o-mini');
const tools = ref([
  {
    type: "function",
    function: {
      description: "通过Google搜索获取指定查询的结果",
      name: "getGoogleSearch",
      parameters: {
        type: "object",
        properties: {
          query: {
            type: "string",
            description: "要搜索的关键词或查询字符串"
          },
        },
        required: ["query"]
      }
    }
  },
  {
    type: "function",
    function: {
      description: "解析指定URL并获取其内容",
      name: "getUrl",
      parameters: {
        type: "object",
        properties: {
          url: {
            type: "string",
            description: "要解析的网页的完整URL地址"
          }
        },
        required: ["url"]
      }
    }
  },
]);

// 获取子组件实例
const Ai = ref(null);

// 处理子组件发出的 run-tool 事件
const runTool = async (toolCalls) => {
  let toolMessagesList = [];

  for (const item of toolCalls) {
    let { id, function: func } = item;
    let { name, arguments: args } = func;
    let funContent = '';

    // 将函数名映射到相应的 API 路径
    const apiMapping = {
      getGoogleSearch: '/api/assistant/tools/search',
      getUrl: '/api/assistant/tools/url'
    };

    try {
      // 根据函数名获取 API 路径
      const apiPath = apiMapping[name];

      if (!apiPath) {
        throw new Error(`未知的函数名: ${name}`);
      }

      // 执行工具调用
      const response = await post(apiPath, JSON.parse(args));
      funContent = JSON.stringify(response);
    } catch (error) {
      funContent = `执行 ${name} 时出错: ${error.message}`;
    }

    toolMessagesList.push({
      tool_call_id: id,
      role: "tool",
      name: name,
      content: funContent,
      expanded: false
    });
  }

  // 将工具响应消息传回子组件
  if (Ai.value && typeof Ai.value.addToolMessages === 'function') {
    Ai.value.addToolMessages(toolMessagesList);
  } else {
    console.error('无法访问子组件的方法 addToolMessages');
  }
};
</script>

