<template>
    <ul class="list-none pl-2 m-0">
      <li v-for="file in files" :key="file.path" class="my-1">
        <div 
          @click="handleClick(file)" 
          class="flex items-center cursor-pointer p-1 rounded hover:bg-gray-100 transition-colors duration-200"
          :class="{
            'font-bold': file.type === 'directory',
            'font-normal': file.type === 'file'
          }"
        >
          <span v-if="file.type === 'directory'" class="mr-2">📁</span>
          <span v-else class="mr-2">📄</span>
          {{ file.name }}{{ file.type === 'directory' ? '/' : '' }}
        </div>
        <tree-view 
          v-if="file.type === 'directory' && file.children"
          :files="file.children"
          @file-click="handleClick"
          class="ml-4"
        />
      </li>
    </ul>
  </template>
  
  <script setup>
  import { defineProps, defineEmits } from 'vue';
  
  const props = defineProps(['files']);
  const emit = defineEmits(['file-click']);
  
  const handleClick = (file) => {
    emit('file-click', file);
  };
  </script>