<template>
    <div class="flex-grow flex flex-col overflow-hidden">
        <div ref="chatContainer" class="flex-grow overflow-y-auto p-4 hide-scrollbar max-w-4xl w-full mx-auto">
            <div v-if="hasMore" class="text-center mb-4">
                <span @click="loadMore" class="text-blue-500 cursor-pointer">加载更多</span>
            </div>
            <div v-for="(message, index) in messages" :key="index">
                <!-- 用户消息 -->
                <div v-if="message.role === 'user'" class="flex justify-end mb-4">
                    <div class="bg-gray-200 rounded-lg py-2 px-4 shadow break-all">
                        {{ message.content }}
                    </div>
                </div>
                <!-- AI助手消息（没有工具调用的情况） -->
                <!-- <div v-else-if="message.role === 'assistant' && !message.tool_calls" class="flex justify-start mb-4">
                    <div class="bg-white text-gray-800 rounded-lg py-2 px-4 shadow break-words"
                        v-html="renderMarkdown(message.content)">
                        {{ message.content }}
                    </div>
                </div> -->
                <!-- AI助手消息（没有工具调用的情况） -->
                <div v-else-if="message.role === 'assistant' && !message.tool_calls" class="flex justify-start mb-4">
                    <div class="bg-white text-gray-800 rounded-lg py-2 px-4 shadow break-words">
                        {{ message.content }}
                    </div>
                </div>

                <!-- AI助手消息（有工具调用的情况） -->
                <div v-else-if="message.role === 'assistant' && message.tool_calls" class="flex justify-start mb-4">
                    <div class="bg-white text-gray-800 rounded-lg py-2 px-4 shadow break-all">
                        {{ message.content }}
                        <div v-for="(toolCall, index) in message.tool_calls" :key="index" class="text-sm text-gray-500">
                            <strong>{{ toolCall.function.name }}</strong> | 请求： {{ toolCall.function.arguments }}
                        </div>
                    </div>
                </div>

                <!-- 工具调用的响应消息 -->
                <div v-else-if="message.role === 'tool'" class="flex justify-start mb-4">
                    <div class="bg-blue-100 text-blue-800 rounded-lg py-2 px-4 shadow break-all text-sm"
                        @click="toggleExpand(index)">
                        <p v-if="!message.expanded">{{ truncateText(message.content) }}</p>
                        <p v-else>{{ message.content }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-white w-full p-4 pt-1">
            <div class="bg-gray-200 flex items-end rounded-[23px] p-[5px] shadow-md max-w-4xl mx-auto">
                <textarea v-model="newMessage" rows="1" :placeholder="thinking ? 'AI正在思考...' : '向AI发送消息'"
                    @input="autoResize" @keydown.enter.prevent="sendMessage" ref="messageInput"
                    class="ml-2 flex-1 p-2 border-none resize-none focus:outline-none overflow-auto min-h-[36px] max-h-[160px] bg-gray-200"></textarea>
                <div @click="sendMessage"
                    :class="['text-2xl mr-1 ml-3 cursor-pointer rounded-full min-h-[38px] min-w-[38px] flex items-center justify-center', thinking ? 'bg-gray-400' : (newMessage === '' ? 'bg-gray-400' : 'bg-black')]">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" fill="none" viewBox="0 0 32 32">
                        <path fill="white" fill-rule="evenodd"
                            d="M15.192 8.906a1.143 1.143 0 0 1 1.616 0l5.143 5.143a1.143 1.143 0 0 1-1.616 1.616l-3.192-3.192v9.813a1.143 1.143 0 0 1-2.286 0v-9.813l-3.192 3.192a1.143 1.143 0 1 1-1.616-1.616z" />
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable no-constant-condition */

import { post, get } from '../utils';
import { marked } from 'marked';

export default {
    data() {
        return {
            newMessage: '',
            tools: [
                {
                    "type": "function",
                    "function": {
                        "description": "通过Google搜索获取指定查询的结果",
                        "name": "getGoogleSearch",
                        "parameters": {
                            "type": "object",
                            "properties": {
                                "query": {
                                    "type": "string",
                                    "description": "要搜索的关键词或查询字符串"
                                },
                            },
                            "required": ["query"]
                        }
                    }
                },
                {
                    "type": "function",
                    "function": {
                        "description": "解析指定URL并获取其内容",
                        "name": "getUrl",
                        "parameters": {
                            "type": "object",
                            "properties": {
                                "url": {
                                    "type": "string",
                                    "description": "要解析的网页的完整URL地址"
                                }
                            },
                            "required": ["url"]
                        }
                    }
                },
            ],

            messages: [],
            thinking: false,
            selectedModel: 'gpt-4o-mini',
            offset: 0,
            limit: 10,
            hasMore: true,
        };
    },
    async mounted() {
        this.scrollToBottom();
    },
    methods: {

        async getHistory() {
            try {
                const response = await get(`/api/assistant/history?offset=${this.offset}&limit=${this.limit}`);
                if (response.results.length < this.limit) {
                    this.hasMore = false;
                }
                const newMessages = response.results.map(record => ({
                    role: record.role,
                    content: record.content,
                    tool_calls: record.tool_calls ? JSON.parse(record.tool_calls) : null,
                    tool_call_id: record.tool_call_id
                }));
                // 将新消息添加到数组的开头，保持最新消息在数组末尾
                this.messages = [...newMessages.reverse(), ...this.messages];
                this.offset += this.limit;
            } catch (error) {
                console.error('获取聊天历史时出错:', error);
            }
        },

        async loadMore() {
            await this.getHistory();
        },
        // eslint-disable-next-line no-unused-vars
        async sendMessage() {
            this.messages.push({ role: 'user', content: this.newMessage });
            this.thinking = true;
            let _newMessages = this.newMessage;
            let _newToolMessages = [];

            this.newMessage = ''; // 清空输入框
            this.autoResize();
            this.scrollToBottom();

            while (true) {
                const filteredMessages = this.messages.map(msg => {
                    if (msg.role === 'tool') {
                        // eslint-disable-next-line no-unused-vars
                        const { expanded, ...rest } = msg; // 过滤掉 expanded 属性
                        return rest;
                    }
                    return msg;
                });

                const aiResponse = await post('/api/assistant/talk', {
                    tools: this.tools,
                    messages: filteredMessages,
                    model: this.selectedModel,
                    newMessage: _newMessages,
                    newToolMessages: _newToolMessages
                });

                if (aiResponse.choices[0].finish_reason == 'stop') {
                    this.messages.push({ role: 'assistant', content: aiResponse.choices[0].message.content });
                    this.thinking = false;
                    this.scrollToBottom();
                    break;
                } else if (aiResponse.choices[0].finish_reason == 'tool_calls') {
                    let tool_calls = aiResponse.choices[0].message.tool_calls;
                    this.messages.push({
                        role: 'assistant',
                        content: aiResponse.choices[0].message.content,
                        tool_calls
                    });
                    let toolMessagesList = await this.runTool(tool_calls);
                    _newToolMessages = toolMessagesList;
                    this.messages.push(...toolMessagesList);
                    this.scrollToBottom();
                }
            }
        },
        async runTool(tools) {

            let toolMessagesList = [];

            for (const item of tools) {
                let { id, function: func } = item;
                let { name, arguments: args } = func;
                let funContent = '';

                // 将函数名映射到相应的 API 路径
                const apiMapping = {
                    getGoogleSearch: '/api/assistant/tools/search',
                    getUrl: '/api/assistant/tools/url'
                };

                try {
                    // 根据函数名获取 API 路径
                    const apiPath = apiMapping[name];

                    if (!apiPath) {
                        throw new Error(`未知的函数名: ${name}`);
                    }

                    const response = await post(apiPath, JSON.parse(args));
                    funContent = JSON.stringify(response);
                } catch (error) {
                    funContent = `执行 ${name} 时出错: ${error}`;
                }

                toolMessagesList.push({
                    tool_call_id: id,
                    role: "tool",
                    name: name,
                    content: funContent,
                    expanded: false
                });
            }

            return toolMessagesList;
        },

        autoResize() {
            this.$nextTick(() => {
                const textarea = this.$refs.messageInput;
                textarea.style.height = 'auto';
                textarea.style.height = textarea.scrollHeight + 'px';
            });
        },

        scrollToBottom() {
            this.$nextTick(() => {
                const container = this.$refs.chatContainer;
                container.scrollTop = container.scrollHeight;
            });
        },

        renderMarkdown(content) {
            return marked(content);
        },

        toggleExpand(index) {
            console.log(index)
            console.log(this.messages[index])
            this.messages[index].expanded = !this.messages[index].expanded;
        },

        truncateText(text) {
            const maxLength = 100;
            return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
        }
    }
};
</script>

<style scoped>
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.hide-scrollbar {
    -ms-overflow-style: none;
    /* IE 11 */
    scrollbar-width: none;
    /* Firefox 64 */
}
</style>