<template>
    <div class="container mx-auto px-4 py-6">
      <!-- <h2 class="text-3xl font-bold mb-6 text-center">每日新闻汇总</h2> -->
  
      <div v-if="loading" class="text-center text-lg">
        <p>正在加载日报...</p>
      </div>
  
      <div v-if="!loading && reports.length === 0" class="text-center text-lg">
        <p>没有找到日报。</p>
      </div>
  
      <div v-if="!loading && reports.length > 0">
        <ul class="relative border-l-4 border-gray-200 dark:border-gray-700">
          <li v-for="report in reports" :key="report.id" class="mb-10 ml-4">
            <!-- 时间点 -->
            <div class="absolute w-3 h-3 bg-blue-600 rounded-full -left-1.5 border border-white dark:border-gray-900"></div>
            
            <!-- 日期和标题 -->
            <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
              {{ report.report_date }}
            </time>
            <h3 class="text-lg font-semibold text-gray-900 dark:text-white">{{ report.report_title }}</h3>
            
            <!-- 每个任务的主标题和子标题 -->
            <ul>
              <li v-for="item in report.report_data.sub_titles" :key="item.title" class="pl-6 mt-2">
                <h4 class="text-md font-bold text-gray-700 dark:text-gray-200">{{ item.title }}</h4>
                <ul class="pl-4 mt-1 list-disc list-inside">
                  <li v-for="sub in item.sub_titles" :key="sub.url">
                    <a :href="sub.url" class="text-blue-500 hover:underline" target="_blank">{{ sub.title }}</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import { get } from '../../utils';
  
  const reports = ref([]);
  const loading = ref(true);
  
  // 获取日报数据
  const fetchReports = async () => {
    try {
      const response = await get('/api/inews/reports');
      reports.value = response;
    } catch (error) {
      console.error('获取日报失败:', error);
    } finally {
      loading.value = false;
    }
  };
  
  // 页面加载时获取日报
  onMounted(fetchReports);
  </script>
  
  <style scoped>
  </style>
  