<template>
    <div class="bg-gray-800 text-white">
      <!-- 加载蒙层 -->
      <div v-if="isLoading" class="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
        <div class="bg-gray-800 w-32 h-32 flex justify-center items-center rounded-lg">
          <span class="text-white text-lg">{{ toast }}</span>
        </div>
      </div>
  
      <!-- 手机模拟屏幕 -->
      <div class="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-10">
        <div class="max-w-xs border-4 border-white p-4 rounded-xl">
          <img v-if="homePage" src="images/aiphone/hand.png" class="w-60 h-60 rounded-xl" />
          <div v-if="!homePage" v-html="timeLine[currentIndex].content"
            class="mt-0 overflow-auto scrollbar-none w-60 h-60 rounded-lg bg-green-400 p-3 box-border text-gray-800">
          </div>
          <div v-if="timeLine.length > 0" class="max-w-xs mx-auto">
            <div v-for="option in timeLine[currentIndex].options" :key="option.text" @click="chooseOption(option)"
              class="p-2 mt-3 bg-gray-800 border border-gray-600 cursor-pointer transition ease-in-out duration-300 rounded-md text-gray-400 text-sm hover:bg-gray-700">
              {{ option.text }}
            </div>
          </div>
  
          <!-- 自定义输入选项 -->
          <div class="flex mb-3">
            <input v-model="customOption" placeholder="输入您的选择"
              class="p-2 mt-3 bg-gray-800 border border-gray-600 cursor-pointer transition ease-in-out duration-300 rounded-md text-gray-400 text-sm" />
            <div @click="chooseOption({ text: customOption })"
              class="w-full text-center p-2 mt-3 bg-gray-800 border border-gray-600 cursor-pointer transition ease-in-out duration-300 rounded-md text-gray-400 text-sm whitespace-nowrap ml-1">
              发送
            </div>
          </div>
  
          <!-- 导航按钮 -->
          <div class="flex justify-between items-center text-gray-500 text-base mt-2">
            <div @click="prevStory" :class="{
              'text-gray-600 cursor-not-allowed': currentIndex === timeLine.length - 1,
              'cursor-pointer': currentIndex !== timeLine.length - 1,
            }">
              上一步
            </div>
            <div class="cursor-pointer" @click="goHomePage">主页</div>
            <div @click="nextStory" :class="{
              'text-gray-600 cursor-not-allowed': currentIndex === 0,
              'cursor-pointer': currentIndex !== 0,
            }">
              下一步
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue'
  
  import { post } from '../utils';
  
  const homePage = ref(true)
  const currentIndex = ref(0)
  const customOption = ref('')
  const timeLine = ref([])
  const isLoading = ref(false)
  const isChoices = ref(false)
  const toast = ref('')
  
  // 初始化方法
  const init = async () => {
    isLoading.value = true
    toast.value = `正在开机...`
    let ai = await post('/api/aiphone', {
      now: '开机画面',
      next: '进入主屏幕',
    })
    const { content, options } = ai
    timeLine.value.push({
      content,
      options,
      choices: null,
    })
    isLoading.value = false
  }
  
  const goHomePage = () => {
    timeLine.value = [
      {
        time: 1,
        content: 'hello',
        options: [
          { text: '打开未读短信', cost: 21 },
          { text: '打开我订阅的新闻', cost: 21 },
          { text: '查看漂流瓶的通知', cost: 21 },
        ],
        choices: null,
      },
    ]
    homePage.value = true
  }
  
  const prevStory = () => {
    if (currentIndex.value < timeLine.value.length - 1) {
      currentIndex.value += 1
    }
  }
  
  const nextStory = () => {
    if (currentIndex.value > 0) {
      currentIndex.value -= 1
    }
  }
  
  const chooseOption = async (choices) => {
    if (currentIndex.value !== 0) {
      alert('已经选过了...')
      return
    }
    if (isChoices.value) {
      return
    }
  
    isChoices.value = true
    isLoading.value = true
    toast.value = `${choices.text}...`
  
    timeLine.value[currentIndex.value].choices = choices.text
  
    const history = timeLine.value.slice(1, 3).reverse().map((item) => {
      return `界面：${item.content}` + (item.choices ? `选择：${item.choices}` : '')
    })
  
    let ai = await post('/api/aiphone', {
      history,
      now: timeLine.value[currentIndex.value].content,
      choices: choices.text,
    })
  
    const { content, options } = ai
    timeLine.value.unshift({
      content,
      options,
      choices: null,
    })
  
    currentIndex.value = 0
    isLoading.value = false
    isChoices.value = false
    homePage.value = false
  }
  
  onMounted(() => {
    init()
  })
  </script>
  
  <style scoped>
  /* 可选样式 */
  </style>