<template>
    <div class="max-w-4xl mx-auto p-4">
      <h2 class="text-2xl font-bold mb-4">{{ isEdit ? 'Edit Task' : 'Create New Task' }}</h2>
  
      <form @submit.prevent="submitForm">
        <div class="mb-4">
          <label class="block text-sm font-bold mb-2" for="task_name">Task Name</label>
          <input
            v-model="taskName"
            type="text"
            id="task_name"
            placeholder="Enter task name"
            class="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
  
        <div class="mb-4">
          <label class="block text-sm font-bold mb-2" for="task_description">Task Description</label>
          <textarea
            v-model="taskDescription"
            id="task_description"
            placeholder="Enter task description (optional)"
            class="w-full p-2 border border-gray-300 rounded"
          ></textarea>
        </div>
  
        <button type="submit" class="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600">
          {{ isEdit ? 'Update Task' : 'Create Task' }}
        </button>
      </form>
  
      <div v-if="message" class="mt-4 p-2 bg-green-100 border border-green-500 text-green-700">
        {{ message }}
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import { post } from '../../utils';
  import { useRoute, useRouter } from 'vue-router';
  
  const taskName = ref('');
  const taskDescription = ref('');
  const message = ref('');
  const isEdit = ref(false);
  const route = useRoute();
  const router = useRouter();
  
  // 根据路由是否有任务ID判断是编辑还是创建
  const taskId = route.params.id;
  
  const fetchTask = async () => {
    try {
      const response = await post('/api/inews/task/detail', { id: taskId });
      taskName.value = response.task_name;
      taskDescription.value = response.task_description;
      isEdit.value = true;
    } catch (error) {
      console.error('Failed to fetch task:', error);
    }
  };
  
  const submitForm = async () => {
    try {
      const endpoint = isEdit.value
        ? '/api/inews/task/edit'
        : '/api/inews/task/create';
  
      const response = await post(endpoint, {
        id: taskId,
        task_name: taskName.value,
        task_description: taskDescription.value,
      });
  
      if (response.message) {
        message.value = response.message;
  
        // 表单提交后，重定向回任务列表页
        setTimeout(() => {
          router.push('/inews/tasks');
        }, 1500);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      message.value = 'Failed to submit task.';
    }
  };
  
  // 如果是编辑任务，则获取任务数据
  if (taskId) {
    onMounted(fetchTask);
  }
  </script>
  
  <style scoped>
  /* TailwindCSS 样式直接应用 */
  </style>
  