<template>
    <div class="flex h-screen">
        <!-- 中间：带标签页的编辑器 -->
        <div class="flex-1 flex flex-col overflow-hidden">
            <!-- 顶部工具栏 -->
            <div class="flex justify-between items-center bg-white border-b border-gray-200 h-[46px] px-4">
                <!-- 自定义版本选择下拉 -->

                <button @click="downloadContent"
                    class="bg-gray-200 text-gray-700 px-4 py-1 rounded hover:bg-gray-300 transition-colors text-sm">
                    返回
                </button>

                <div class="relative">
                    <div class="bg-gray-200 px-4 py-1.5 cursor-pointer rounded text-sm flex justify-between items-center"
                        @click="toggleDropdown">
                        <span>{{ versionHistory.length > 0 ? versionHistory[selectedVersion].name : '新文档' }}</span>
                        <span class="ml-2">></span>
                    </div>
                    <ul v-if="showDropdown"
                        class="absolute bg-white border border-gray-200 mt-1 rounded w-full z-10 max-h-60 overflow-y-auto">
                        <li v-for="(version, index) in versionHistory" :key="index" @click="selectVersion(index)"
                            class="px-4 py-2 cursor-pointer hover:bg-gray-100 text-sm">
                            <div>{{ version.name }}</div>
                            <div class="text-xs text-gray-500">{{ version.timestamp }}</div>
                        </li>
                    </ul>
                </div>

                <!-- 复制、下载按钮 -->
                <div class="flex space-x-2">
                    <button @click="copyContent"
                        class="bg-gray-200 text-gray-700 px-4 py-1 rounded hover:bg-gray-300 transition-colors text-sm">
                        复制
                    </button>
                    <button @click="downloadContent"
                        class="bg-gray-200 text-gray-700 px-4 py-1 rounded hover:bg-gray-300 transition-colors text-sm">
                        下载
                    </button>
                </div>
            </div>

            <!-- 内容标签页 -->
            <div v-if="activeTab === 'content'" class="flex-1 overflow-y-auto">
                <div class="h-full bg-white p-4">
                    <textarea v-model="editorContent"
                        class="w-full h-full resize-none outline-none bg-transparent custom-scrollbar"
                        @input="updateBlocks" @keydown.enter="handleEnter" />
                </div>
            </div>

            <!-- 数据标签页 -->
            <div v-else-if="activeTab === 'data'" class="flex-1 overflow-y-auto p-6">
                <pre class="text-sm overflow-x-auto">{{ JSON.stringify(blocks, null, 2) }}</pre>
            </div>
        </div>

        <!-- 右侧：模型选择和聊天组件 -->
        <div class="w-[400px] flex flex-col border-l border-gray-200">
            <!-- 模型选择下拉菜单 -->
            <!-- <div class="flex justify-center items-center p-2 border-b border-gray-200 h-[46px]">
                <select v-model="selectedModel" class="p-1 text-sm border border-gray-300 rounded">
                    <option v-for="model in models" :key="model" :value="model">
                        {{ model }}
                    </option>
                </select>
            </div> -->

            <!-- <div class="flex justify-center items-center p-2 border-b border-gray-200 h-[46px]">
                智能写作助理
            </div> -->
            <!-- 聊天组件 -->
            <chat :model="selectedModel" :tools="tools" :appDataSync="appDataSync" :getAppData="getAppData"
                :runTool="runTool" ref="Ai" />
        </div>
    </div>
</template>


<script setup>
import { ref, onMounted, nextTick } from 'vue';
import chat from '../../components/AI/chat/anthropic/stream.vue';
import { post } from '../../utils';

// 模型列表
// const models = [
//     'claude-3-5-sonnet-20240620',
//     'claude-3-opus-20240229',
// ];

const selectedModel = ref('claude-3-5-sonnet-20240620');
const appDataSync = ref(true);
const activeTab = ref('content');

const generateRandomId = () => Math.random().toString(36).substring(2, 8);

const blocks = ref([
    { id: generateRandomId(), type: 'text', content: '' }
]);

const versionHistory = ref([]);  // 添加 versionHistory 的声明

const selectedVersion = ref(0); // 默认选中的版本索引
const showDropdown = ref(false); // 控制下拉框显示
const editorContent = ref('');

// 下拉框切换显示
const toggleDropdown = () => {
    showDropdown.value = !showDropdown.value;
};

// 选择版本
const selectVersion = (index) => {
    selectedVersion.value = index;
    editorContent.value = versionHistory.value[index].content; // 恢复该版本的内容
    showDropdown.value = false; // 关闭下拉菜单
};

const getAppData = () => {
    return `你是一个专门解决写作的人工智能助手，你能够在对话中领会用户的需求然后通过工具操作用户的编辑器。以下是当前编辑器的结构和内容：`
        + JSON.stringify(blocks.value)
};

// 工具列表
const tools = ref([
    {
        type: "function",
        function: {
            name: "write",
            description: "在编辑器中开始书写，如果编辑器已经存在内容，则会在内容后面续写",
            parameters: {
                type: "object",
                properties: {
                    content: {
                        type: "string",
                        description: "要续写的内容",
                    },
                },
                required: ["content"],
            }
        }
    },
    {
        type: "function",
        function: {
            name: "edit",
            description: "编辑指定块的内容",
            parameters: {
                type: "object",
                properties: {
                    blockId: {
                        type: "string",
                        description: "要编辑的块的 ID",
                    },
                    newContent: {
                        type: "string",
                        description: "块的新内容",
                    },
                },
                required: ["blockId", "newContent"],
            },
        },
    },
    {
        type: "function",
        function: {
            name: "insert",
            description: "在指定段落之后插入新段落",
            parameters: {
                type: "object",
                properties: {
                    blockId: {
                        type: "string",
                        description: "目标段落的ID",
                    },
                    newContent: {
                        type: "string",
                        description: "要插入的内容",
                    },
                },
                required: ["blockId", "newContent"],
            }
        }
    },
    {
        type: "function",
        function: {
            name: "delete",
            description: "删除指定段落",
            parameters: {
                type: "object",
                properties: {
                    blockId: { type: "string", description: "要删除的段落的ID" }
                },
                required: ["blockId"]
            }
        }
    },
    {
        type: "function",
        function: {
            name: "clear",
            description: "清空编辑器中的所有内容",
            parameters: {
                type: "object",
                properties: {},
                required: []
            }
        }
    },
    {
        type: "function",
        function: {
            name: "searchWeb",
            description: "在互联网上搜索内容",
            parameters: {
                type: "object",
                properties: {
                    query: { type: "string", description: "要搜索的关键词" }
                },
                required: ["query"]
            }
        }
    },
    {
        type: "function",
        function: {
            name: "getUrl",
            description: "获取url内容",
            parameters: {
                type: "object",
                properties: {
                    url: { type: "string", description: "要获取的URL" }
                },
                required: ["url"]
            }
        }
    }
]);

// 更新块内容，根据换行符将内容分段
const updateBlocks = () => {
    const paragraphs = editorContent.value.split('\n');
    blocks.value = paragraphs.map(paragraph => ({
        id: generateRandomId(),
        type: 'text',
        content: paragraph
    }));
};

// 处理 Enter 键
const handleEnter = (event) => {
    if (!event.shiftKey) {
        event.preventDefault(); // 禁用 Enter 换行行为
        const caretPosition = event.target.selectionStart;
        editorContent.value = editorContent.value.slice(0, caretPosition) + '\n' + editorContent.value.slice(caretPosition);
        nextTick(() => {
            event.target.selectionStart = caretPosition + 1;
            event.target.selectionEnd = caretPosition + 1;
            updateBlocks();
        });
    }
};

// 工具调用函数
const runTool = async (toolCalls) => {
    const toolResults = [];
    let funContent = '';

    for (const call of toolCalls) {

        const parsedArgs = call.input;

        switch (call.name) {
            case 'write':
                {
                    const { content } = parsedArgs;
                    editorContent.value += '\n' + content; // 将新内容追加到编辑器内容
                    updateBlocks(); // 更新块
                    funContent = `已写入新内容`;
                }
                break;

            case 'edit':
                {
                    const { blockId, newContent } = parsedArgs;
                    const block = blocks.value.find((b) => b.id === blockId);
                    if (block) {
                        block.content = newContent;
                        editorContent.value = blocks.value.map(block => block.content).join('\n'); // 同步编辑器内容
                        funContent = `编辑成功`;
                    } else {
                        funContent = `找不到 ID 为 ${blockId} 的块。`;
                    }
                }
                break;

            case 'insert':
                {
                    const { blockId, newContent } = parsedArgs;
                    const index = blocks.value.findIndex(block => block.id === blockId);
                    if (index !== -1) {
                        const newBlock = {
                            id: generateRandomId(),
                            type: 'text',
                            content: newContent
                        };
                        blocks.value.splice(index + 1, 0, newBlock); // 在指定块之后插入新块
                        editorContent.value = blocks.value.map(block => block.content).join('\n'); // 同步编辑器内容
                        funContent = `已插入了新内容`;
                    } else {
                        funContent = `找不到 ID 为 ${blockId} 的块。`;
                    }
                }
                break;

            case 'delete':
                {
                    const { blockId } = parsedArgs;
                    const index = blocks.value.findIndex(block => block.id === blockId);
                    if (index !== -1) {
                        blocks.value.splice(index, 1); // 删除指定块
                        editorContent.value = blocks.value.map(block => block.content).join('\n'); // 同步编辑器内容
                        funContent = `删除了内容。`;
                    } else {
                        funContent = `找不到 ID 为 ${blockId} 的块。`;
                    }
                }
                break;

            case 'clear':
                {
                    blocks.value = [{
                        id: generateRandomId(),
                        type: 'text',
                        content: ''
                    }];
                    editorContent.value = ''; // 清空编辑器内容
                    funContent = '编辑器已清空。';
                }
                break;

            case 'searchWeb':
                {
                    try {
                        const response = await post('/api/assistant/tools/search', parsedArgs); // 等待 post 完成
                        funContent = JSON.stringify(response); // 获取并设置搜索结果
                    } catch (error) {
                        console.error(error);
                        funContent = '搜索出错了。';
                    }
                }
                break;

            case 'getUrl':
                {
                    try {
                        const response = await post('/api/assistant/tools/url', parsedArgs); // 等待 post 完成
                        funContent = JSON.stringify(response); // 获取并设置URL结果
                    } catch (error) {
                        console.error(error);
                        funContent = '获取URL时出错了。';
                    }
                }
                break;
            default:
                funContent = `未识别的工具调用: ${call.name}`;
        }

        toolResults.push({
            type: 'tool_result',
            tool_use_id: call.id,
            content: funContent
        });
    }

    // 在每次操作后添加版本记录
    addVersion(funContent);

    return toolResults; // 返回工具调用的结果
};

// 复制和下载功能
const copyContent = () => {
    navigator.clipboard.writeText(editorContent.value)
        .then(() => alert('内容已复制'))
        .catch(() => alert('复制失败'));
};

const downloadContent = () => {
    const blob = new Blob([editorContent.value], { type: 'application/msword' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `content-${Date.now()}.doc`;
    a.click();
    URL.revokeObjectURL(url);
};

// 添加版本历史记录
const addVersion = (operationName) => {
    const newVersion = {
        name: `${operationName}`,
        content: editorContent.value,
        timestamp: new Date().toLocaleString(),
    };
    versionHistory.value.unshift(newVersion);
    selectedVersion.value = 0; // 自动选中最新版本
};
onMounted(() => {
    nextTick(() => {
        updateBlocks();
    });
});
</script>
