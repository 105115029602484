import { createRouter, createWebHistory } from 'vue-router';

import Talk from '../views/Talk.vue';
import Talking from '../views/Talking.vue';
import Git from '../views/Git.vue';
// 分析器
import Sql from '../views/analyzer/Sql.vue';
import View from '../views/analyzer/View.vue';
import List from '../views/analyzer/List.vue';
import Active from '../views/analyzer/Active.vue';
import Board from '../views/analyzer/Board.vue';

import aiPhone from '../views/aiPhone.vue';
import Travel from '../views/Travel.vue';
import AiVision from '../views/AiVision.vue';
import Reasoning from '../views/Reasoning.vue';
import o1 from '../views/o1.vue';
import discuss from '../views/discuss';
import discussDetail from '../views/discuss/detail.vue';

import Inews from '../views/inews/Inews.vue';
import DailyReports from '../views/inews/DailyReports.vue';
import TaskManagement from '../views/inews/TaskManagement.vue';
import TaskForm from '../views/inews/TaskForm.vue';

import daily from '../views/daily.vue';

import o1Git from '../views/o1Git.vue';

import gptWriting from '../views/writing/gpt.vue';

import claudeWriting from '../views/writing/claude.vue';

import look from '../views/look.vue';

const routes = [
  { path: '/', redirect: '/talking' },
  { path: '/talk', component: Talk },
  { path: '/talking', component: Talking },
  { path: '/git', component: Git },
  // 分析器
  { path: '/analyzer/sql', component: Sql },
  { path: '/analyzer/list', component: List },
  { path: '/analyzer/view', component: View },
  { path: '/analyzer/active', component: Active },
  { path: '/analyzer/board', component: Board },

  { path: '/aiphone', component: aiPhone },

  { path: '/travel', component: Travel },

  { path: '/aivision', component: AiVision },

  { path: '/reasoning', component: Reasoning },

  { path: '/o1', component: o1 },

  { path: '/discuss', component: discuss },
  { path: '/discuss/:id', component: discussDetail },

  {
    path: '/inews',

    component: Inews,
    children: [
      {
        path: 'reports',
        component: DailyReports,
      },
      {
        path: 'tasks',
        component: TaskManagement,
      },
      {
        path: 'task/create',
        component: TaskForm,
      },
      {
        path: 'task/edit/:id',
        component: TaskForm,
        props: true,
      },
      {
        path: '', // 当访问 /inews 时
        redirect: '/inews/reports', // 重定向到 /inews/reports
      },
    ],
  },

  { path: '/daily', component: daily },

  { path: '/o1git', component: o1Git },

  { path: '/writing/gpt', component: gptWriting },
  { path: '/writing/claude', component: claudeWriting },

  { path: '/look', component: look },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


export default router;
