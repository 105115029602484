<template>
  <div class="flex-grow flex flex-col overflow-hidden">
    <!-- 聊天内容区域 -->
    <div ref="chatContainer" class="flex-grow overflow-y-auto mt-2 px-4 hide-scrollbar max-w-4xl w-full mx-auto">
      <!-- 消息列表 -->
      <div v-for="(message, index) in messages" :key="index">
        <!-- 用户消息 -->
        <div v-if="message.role === 'user'" class="flex justify-end mb-4">
          <div class="bg-gray-200 rounded-lg py-2 px-4 shadow break-all">
            <div v-for="(contentBlock, idx) in message.content" :key="idx">
              <span v-if="contentBlock.type === 'tool_result'" class="text-sm text-gray-500 mt-2">
                {{ contentBlock.content }}
              </span>
              <span v-if="contentBlock.type === 'text'" class="text-gray-800">
                {{ contentBlock.text }}
              </span>
            </div>
          </div>
        </div>

        <!-- AI助手消息 -->
        <div v-else-if="message.role === 'assistant'" class="flex flex-col justify-start mb-4">
          <div class="bg-white text-gray-800 rounded-lg py-2 px-4 shadow break-words">
            <div v-for="(contentBlock, idx) in message.content" :key="idx">
              <div v-if="contentBlock.type === 'text'" v-html="markdown(contentBlock.text)"></div>
              <div v-else-if="contentBlock.type === 'tool_use'" class="text-sm text-gray-500 mt-2">
                <strong>{{ contentBlock.name }}</strong>
                <pre class="whitespace-pre-wrap">{{ JSON.stringify(contentBlock.input, null, 2) }}</pre>
              </div>
            </div>

            <div v-if="message.cost && message.cost.input && message.cost.output"
              class="mt-2 text-sm text-gray-400 flex space-x-4">
              <div>
                输入:{{ message.cost.input }}
              </div>
              <div>
                输出:{{ message.cost.output }}
              </div>
              <div>
                成本:${{ (message.cost.input * 0.000003 + message.cost.output * 0.000015).toFixed(6) }}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- 输入区域 -->
    <div class="bg-white w-full px-4 pt-1">
      <div class="bg-gray-200 flex items-end rounded-[23px] p-[5px] shadow-md max-w-4xl mx-auto">
        <textarea v-model="newMessage" rows="1" :placeholder="thinking ? 'AI正在思考...' : '向AI发送消息'" @input="autoResize"
          @keydown.enter.prevent="sendMessage" ref="messageInput"
          class="ml-2 flex-1 p-2 border-none resize-none focus:outline-none overflow-auto min-h-[36px] max-h-[160px] bg-gray-200 custom-scrollbar"></textarea>
        <div @click="sendMessage" :class="[
          'text-2xl mr-1 ml-3 cursor-pointer rounded-full min-h-[38px] min-w-[38px] flex items-center justify-center',
          thinking ? 'bg-gray-400' : (newMessage === '' ? 'bg-gray-400' : 'bg-black')
        ]">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" fill="none" viewBox="0 0 32 32">
            <path fill="white" fill-rule="evenodd"
              d="M15.192 8.906a1.143 1.143 0 0 1 1.616 0l5.143 5.143a1.143 1.143 0 0 1-1.616 1.616l-3.192-3.192v9.813a1.143 1.143 0 0 1-2.286 0v-9.813l-3.192 3.192a1.143 1.143 0 1 1-1.616-1.616z" />
          </svg>
        </div>
      </div>
    </div>
    <div class="text-center text-gray-300 p-1 text-sm">claude</div>
  </div>
</template>


<script setup>
import {
  ref,
  reactive,
  onMounted,
  nextTick,
  defineProps,
  defineExpose,
} from 'vue';
import { marked } from 'marked';

// 定义 props
const props = defineProps({
  model: {
    type: String,
    required: true,
  },
  tools: {
    type: Array,
    required: true,
  },
  appDataSync: {
    type: Boolean,
    required: true,
  },
  getAppData: {
    type: Function,
    required: true,
  },
  runTool: {
    type: Function,
    required: true,
  },
});

// 定义响应式变量
const newMessage = ref('');
const messages = reactive([]); // 不包含 system 角色，所有 content 都是数组
const thinking = ref(false);
const chatContainer = ref(null);
const messageInput = ref(null);

// 初始化消息
onMounted(() => {
  scrollToBottom();
});

// 发送消息
const sendMessage = async () => {
  if (newMessage.value.trim() === '') return;

  // 添加用户消息，content 为数组
  messages.push({
    role: 'user',
    content: [
      {
        type: 'text',
        text: newMessage.value.trim(),
      },
    ],
  });
  thinking.value = true;
  newMessage.value = '';
  autoResize();
  scrollToBottom();
  await ai();
};

// 处理AI响应
const ai = async () => {
  thinking.value = true;

  // console.log(JSON.stringify(props.tools))

  const requestBody = JSON.stringify({
    model: props.model,
    max_tokens: 8129, // 根据需要设置
    tools: transformToolsToClaude(props.tools),
    system: props.appDataSync ? props.getAppData() : '',
    messages: prepareMessagesForAPI(),
  });

  // console.log(JSON.stringify(transformToolsToClaude(props.tools)))

  try {
    const response = await fetch('/api/ai/chat/anthropic/talking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: requestBody,
    });

    if (!response.body) {
      throw new Error('ReadableStream 不可用');
    }

    // 添加一个新的 assistant 消息，初始化 content 为数组，并添加 cost 对象
    messages.push({
      role: 'assistant',
      content: [
        {
          type: 'text',
          text: ' ', // 初始化为空字符串
        },
      ],
      cost: {
        input: 0,
        output: 0,
      },
    });
    scrollToBottom();

    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');

    let buffer = '';

    let toolInput = '';
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      buffer += decoder.decode(value, { stream: true });
      const chunks = buffer.split('\n\n');

      for (const chunk of chunks.slice(0, -1)) {
        const lines = chunk.split('\n');
        console.log('原始消息', lines);
        let eventType = '';
        let eventData = null;

        // 解析每行内容，找到 event 和 data
        for (const line of lines) {
          if (line.startsWith('event: ')) {
            eventType = line.slice(7);
          } else if (line.startsWith('data: ')) {
            try {
              eventData = JSON.parse(line.slice(6));
            } catch (error) {
              console.error('Error parsing JSON in SSE data:', error);
            }
          }
        }

        // 根据 eventType 来处理不同的事件
        if (eventType && eventData) {
          switch (eventType) {
            case 'message_start': {
              console.log(
                `模型: ${eventData.message.model}, 输入消耗:${eventData.message.usage.input_tokens}`
              );
              // 存储 input_tokens
              const lastMessage = messages[messages.length - 1];
              if (lastMessage.role === 'assistant') {
                lastMessage.cost.input = eventData.message.usage.input_tokens
                  ? eventData.message.usage.input_tokens
                  : 0;
              }
              break;
            }

            case 'content_block_start': {
              if (eventData.content_block?.type === 'text') {
                // 文本内容块开始，可以初始化或做其他处理
              } else if (eventData.content_block?.type === 'tool_use') {
                // push
                messages[messages.length - 1].content.push({
                  type: 'tool_use',
                  id: eventData.content_block.id,
                  name: eventData.content_block.name,
                  input: {},
                });
              }
              break;
            }

            case 'content_block_delta': {
              if (eventData.delta?.type === 'text_delta') {
                const textBlock = messages[messages.length - 1].content.find(
                  (block) => block.type === 'text'
                );
                if (textBlock) {
                  textBlock.text += eventData.delta.text;
                }
                scrollToBottom();
              } else if (eventData.delta?.type === 'input_json_delta') {
                toolInput += eventData.delta.partial_json;
                // console.log('工具参数积累',toolInput)
              }
              break;
            }

            case 'content_block_stop': {
              // console.log('内容块结束');
              if (toolInput) {
                const lastMessage = messages[messages.length - 1];
                const toolUseBlock = lastMessage.content[lastMessage.content.length - 1];
                if (toolUseBlock && toolUseBlock.type === 'tool_use') {
                  toolUseBlock.input = JSON.parse(toolInput);
                }
                toolInput = '';
              }
              break;
            }

            case 'message_delta': {
              // completion_tokens = eventData.usage.output_tokens

              console.log(`输出消耗:${eventData.usage.output_tokens}`);
              const assistantMsg = messages[messages.length - 1];
              if (assistantMsg.role === 'assistant') {
                assistantMsg.cost.output = eventData.usage.output_tokens
                  ? eventData.usage.output_tokens
                  : 0;
              }
              if (eventData.delta?.stop_reason === 'end_turn') {
                console.log('普通消息处理完成');
                thinking.value = false;
                scrollToBottom();
              } else if (eventData.delta?.stop_reason === 'tool_use') {
                const toolCalls = messages[messages.length - 1].content.filter(
                  (item) => item.type === 'tool_use'
                );

                scrollToBottom();

                const toolResults = await props.runTool(toolCalls);

                console.log('工具执行结果', toolResults);

                messages.push({
                  role: 'user',
                  content: toolResults,
                });
                scrollToBottom();
                ai();
              }
              break;
            }

            case 'ping':
              // 跳过 ping 事件
              break;

            case 'message_stop':
              // 跳过 message_stop 事件
              // console.log('结束打印一下最新的消息列表', messages)
              break;
          }
        }
      }
      // 保留未处理的部分
      buffer = chunks[chunks.length - 1];
    }
  } catch (error) {
    console.error('发送消息时出错:', error);
    thinking.value = false;
  }
};

// 准备发送给API的消息格式，过滤掉 cost 字段
const prepareMessagesForAPI = () => {
  return messages.map((message) => {
    const { cost, ...rest } = message;
    return rest;
  });
};

const transformToolsToClaude = (tools) =>
  tools.map(({ function: func }) => ({
    name: func.name,
    description: func.description,
    input_schema: {
      type: func.parameters.type,
      properties: func.parameters.properties,
      required: func.parameters.required
    }
  }));

// 自动调整文本框高度
const autoResize = () => {
  nextTick(() => {
    const textarea = messageInput.value;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  });
};

// 自动滚动到最底部
const scrollToBottom = () => {
  nextTick(() => {
    const container = chatContainer.value;
    container.scrollTop = container.scrollHeight;
  });
};

// 转换 Markdown 内容
const markdown = (content) => {
  return marked(content);
};

// 发送开始消息
const sendStartMessage = async (message) => {
  messages.push({
    role: 'user',
    content: [
      {
        type: 'text',
        text: message,
      },
    ],
  });
  thinking.value = true;
  await ai();
};

// 暴露方法给父组件
defineExpose({
  sendStartMessage,
});
</script>
