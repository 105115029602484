<template>
    <div class="w-full mx-auto p-4">
      <nav class="flex justify-center space-x-8 mb-6 border-b-2 border-gray-200">
        <router-link 
          to="/inews/reports" 
          class="pb-2 text-lg font-semibold"
          :class="{'text-blue-500 border-b-2 border-blue-500': isActive('/inews/reports')}"
        >
          Daily Reports
        </router-link>
  
        <router-link 
          to="/inews/tasks" 
          class="pb-2 text-lg font-semibold"
          :class="{'text-blue-500 border-b-2 border-blue-500': isActive('/inews/tasks')}"
        >
          Task Management
        </router-link>
      </nav>
  
      <!-- 渲染子路由组件 -->
      <router-view></router-view>
    </div>
  </template>
  
  <script setup>
  import { useRoute } from 'vue-router';
  
  // 获取当前激活的路由
  const route = useRoute();
  
  // 检查当前路径是否与给定路径匹配，以确定激活状态
  const isActive = (path) => {
    return route.path.startsWith(path);
  };
  </script>
  
  <style scoped>
  /* 使用 TailwindCSS 样式 */
  </style>
  