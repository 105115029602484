<template>
    <div class="bg-gray-800">
      <!-- 加载蒙层 -->
      <div v-if="isLoading" class="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
        <div class="bg-gray-800 w-32 h-32 flex justify-center items-center rounded-lg">
          <span class="text-white text-lg">{{ toast }}</span>
        </div>
      </div>
  
      <!-- 导航栏 -->
      <div id="navbar" class="p-4 bg-gray-300 z-10">
        <div class="flex justify-between items-center">
          <div
            @click="prevStory"
            :class="{
              'text-gray-600 cursor-not-allowed': currentStoryIndex === journey.length - 1,
              'cursor-pointer': currentStoryIndex !== journey.length - 1,
            }"
          >
            上一程
          </div>
          <div class="text-center text-gray-800">第{{ journey[currentStoryIndex].step }}程</div>
          <div
            @click="nextStory"
            :class="{
              'text-gray-600 cursor-not-allowed': currentStoryIndex === 0,
              'cursor-pointer': currentStoryIndex !== 0,
            }"
          >
            下一程
          </div>
        </div>
        <img :src="journey[currentStoryIndex].imageURL" class="mt-6 mx-auto w-72 h-72 rounded-2xl border-8 border-white shadow-lg" />
        <div class="flex justify-center items-center mt-6">
          <div class="px-4 py-2 bg-white border border-gray-300 rounded-l-full text-center text-gray-800">
            🌍 {{ journey[currentStoryIndex].place }}
          </div>
          <div class="px-4 py-2 bg-white border border-gray-300 rounded-r-full text-center text-gray-800">
            💰 {{ journey[currentStoryIndex].balance }}
          </div>
        </div>
      </div>
  
      <!-- 故事内容 -->
      <div class="px-4 py-2 max-w-3xl mx-auto mt-3 text-white">{{ journey[currentStoryIndex].story }}</div>
  
      <!-- 选项容器 -->
      <div class="px-4 py-2 max-w-3xl mx-auto text-gray-800">
        <div
          v-for="(option, index) in journey[currentStoryIndex].options"
          :key="index"
          @click="chooseOption(option)"
          :class="{
            'bg-gray-300': journey[currentStoryIndex].choices && option.text === journey[currentStoryIndex].choices && currentStoryIndex !== 0,
          }"
          class="p-2 my-3 bg-white border border-gray-300 cursor-pointer transition ease-in-out duration-300 rounded-full hover:bg-gray-200"
        >
          ￥{{ option.cost }} {{ option.text }}
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue'
  
  const currentStoryIndex = ref(0)
  const journey = ref([
    {
      step: 1,
      place: '家里',
      story:
        '我突然从沉睡中惊醒，心跳加速。一看闹钟，才发现自己比预定时间晚起了近一个小时。我的心里一阵慌乱，因为我今天要开始的虚拟环球之旅的第一张车票已经快要发车了。我连忙从床上跳下来，匆忙穿衣，抓起背包，准备出门。',
      imageURL: 'images/travel/init.png',  //src="../../public/images/aiphone/hand.png"
      options: [
        { text: '打车去车站', cost: 38 },
        { text: '乘坐地铁到车站', cost: 6 },
        { text: '骑自行车去车站', cost: 2 },
      ],
      choices: null,
      balance: 100000,
    },
  ])
  const isLoading = ref(false) // 控制加载蒙层的显示
  const toast = ref('')
  const isChoices = ref(false) // 用于控制是否已经选择
  
  const prevStory = () => {
    if (currentStoryIndex.value < journey.value.length - 1) {
      currentStoryIndex.value += 1
    }
  }
  
  const nextStory = () => {
    if (currentStoryIndex.value > 0) {
      currentStoryIndex.value -= 1
    }
  }
  
  const chooseOption = async (choices) => {
    if (currentStoryIndex.value !== 0) {
      alert('已经选过了...')
      return
    }
    if (isChoices.value) {
      return
    }
  
    isLoading.value = true // 开始加载，显示蒙层
    isChoices.value = true // 标记已选择，防止重复选择
    toast.value = `${choices.text}...`
  
    const recentJourneys = journey.value.slice(-3).map((item) => ({
      place: item.place,
      story: item.story,
      choices: item.choices,
    }))
    const lastJourney = journey.value[currentStoryIndex.value]
  
    try {
      const response = await fetch('/api/travel', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          recentJourneys,
          lastJourney,
          choices,
        }),
      })
  
      const result = await response.json()
  
      if (response.ok) {
        const { step, place, story, image, options, balance } = result
  
        journey.value.unshift({
          step,
          place,
          story: '',
          imageURL: image,
          options: [],
          choices: null,
          balance,
        })
  
        currentStoryIndex.value = 0
  
        // 逐字显示文本
        let index = 0
        const displayInterval = setInterval(() => {
          if (index < story.length) {
            journey.value[0].story += story.charAt(index)
            index++
          } else {
            journey.value[0].options = options
            clearInterval(displayInterval) // 清除定时器
          }
        }, 50)
  
        isLoading.value = false
        isChoices.value = false
      } else {
        throw new Error(result.message || '请求失败')
      }
    } catch (error) {
      toast.value = error.message || '请求出错，请重试'
      isLoading.value = false
      isChoices.value = false
    }
  }
  </script>
  
  <style scoped>
  /* 可选的样式 */
  </style>
  